import React from "react";
import styled from "styled-components";

const P = styled.p`
  font-family: "Georgia", serif;
  text-align: center;
  font-style: italic;
  font-size: 18px;
  line-height: 165%;
  width: 90%;
  margin: 3rem auto 4.5rem auto;

  &.small {
    font-size: 16px;
    margin: 1.5rem auto 1.5rem auto;
  }
`;

const Citation = ({ children, ...others }) => <P {...others}>{children}</P>;

export default Citation;
