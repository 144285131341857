import React, { Component } from "react";
import { Link } from "@reach/router";
import Produit from "../../innedit_src/datas/Produit";
import { initProduit } from "../../innedit_src/functions/Demande";
import { Produit as ProduitStyled } from "./styles";

class Request extends Component {
  state = {
    produit: undefined,
    produitId: undefined,
    mustBeLoaded: false
  };

  constructor(props) {
    super(props);

    this.loadProduit = this.loadProduit.bind(this);
  }

  componentDidMount() {
    if (this.state.mustBeLoaded) {
      this.loadProduit(this.state.demandeId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoaded) {
      this.loadProduit(this.state.demandeId);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.item &&
      props.item.produit_id &&
      (!state.produitId || state.produitId !== props.item.produit_id)
    ) {
      state.produitId = props.item.produit_id;
      state.mustBeLoaded = true;
    }

    return state;
  }

  loadProduit() {
    const _this = this;
    this.setState({
      mustBeLoaded: false
    });

    Produit.findById(this.state.produitId).then(document => {
      _this.setState(
        {
          produit: initProduit(
            {
              ...document.data(),
              prixDeVente: _this.props.item.produit_prixDeVente,
              prixUnite: _this.props.item.produit_unite,
              prixBarre: _this.props.item.produit_prixBarre
            },
            this.props.deliveryCostVolume
          )
        },
        () => {
          this.props.handleSetProduit(this.props.index, this.state.produit);
        }
      );
    });
  }

  render() {
    const {
      item: { produit_photo, produit_prixDeVente, produit_prixBarre, content }
    } = this.props;

    return (
      <ProduitStyled
        isProduit={Boolean(this.props.item.produit_id)}
        isBuy={this.props.item.isBuy}
      >
        {this.state.produit && (
          <div className="produit">
            {produit_photo && (
              <img
                className="produit-photo"
                height={80}
                width={80}
                alt={this.state.produit.name}
                src={`${
                  process.env.REACT_APP_CDN_DOMAIN_NAME
                }/${produit_photo}`}
              />
            )}
            <div className="demande">
              <div className="produit">
                <div className="infos">
                  <span className="name">
                    <Link to={"/" + this.state.produit.pathname}>
                      {this.state.produit.name}
                    </Link>
                  </span>
                  <ul>
                    <li>
                      <ul>
                        {this.state.produit.diametre ? (
                          <li>
                            Dimensions : {this.state.produit.diametre} cm de
                            diamètre sur {this.state.produit.height} cm (
                            {this.state.produit.volume} m<sup>3</sup>)
                          </li>
                        ) : (
                          <li>
                            Dimensions : {this.state.produit.depth} x{" "}
                            {this.state.produit.width} sur{" "}
                            {this.state.produit.height} cm (
                            {this.state.produit.volume} m<sup>3</sup>)
                          </li>
                        )}
                        {this.state.produit.min > 1 && (
                          <li>Quantité minimale : {this.state.produit.min}</li>
                        )}
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="last-column">
                  {this.state.produit.isSold ? (
                    <div className="prix">{this.state.produit.stock}</div>
                  ) : (
                    <React.Fragment>
                      <div className="prix">
                        {produit_prixDeVente}€ {this.state.produit.unite}
                        {produit_prixBarre && (
                          <span className="prix-infos">
                            (
                            <span className="prix-barre">
                              {produit_prixBarre}
                            </span>
                            € {this.state.produit.remise})
                          </span>
                        )}
                      </div>
                      <button
                        onClick={e =>
                          this.props.handleToggleIsBuy(e, this.props.index)
                        }
                      >
                        {this.props.item.isBuy ? "Retirer" : "Ajouter"}
                      </button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {content && (
          <div className="conversation">
            <div className="message">{content}</div>
            {this.props.item.reponse && (
              <div className="message-reponse">{this.props.item.reponse}</div>
            )}
          </div>
        )}
      </ProduitStyled>
    );
  }
}

export default Request;
