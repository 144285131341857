import React, { Component } from "react";
import styled from "styled-components";
import PopupDemande from "./Popup/DemandeRenseignement";
import { useTranslation } from "react-i18next";

const Span = styled.span`
  display: block;

  &.price,
  &.start_price {
    &:after {
      content: " €";
      //margin-left: 0.1875rem;
      font-size: 85%;
    }
  }
`;

class Prix extends Component {
  state = {
    showPopupDemande: false,
    isDemandeSuccessed: false,
  };

  constructor(props) {
    super(props);

    this.handleOpenPopupDemande = this.handleOpenPopupDemande.bind(this);
    this.handleClosePopupDemande = this.handleClosePopupDemande.bind(this);
  }

  handleOpenPopupDemande = (e) => {
    e.preventDefault();
    this.setState({
      showPopupDemande: true,
    });
  };

  handleClosePopupDemande(isSuccessed) {
    this.setState({
      showPopupDemande: false,
      isDemandeSuccessed: isSuccessed,
    });
  }

  render() {
    const produit = this.props.produit.data();
    const t = this.props.t;

    const achatPossible =
      (produit.hasInventory &&
        (produit.qtyAvailable > 0 || produit.negativeStock)) ||
      !produit.hasInventory;

    if (achatPossible) {
      const retailPrice = produit.retailPrice
        ? produit.shippingIncluded
          ? parseFloat(produit.retailPrice) +
            parseFloat(produit.estimateShippingCost)
          : produit.retailPrice
        : false;
      const price = produit.shippingIncluded
        ? parseFloat(produit.price) + parseFloat(produit.estimateShippingCost)
        : produit.price;

      let PriceCmpt;
      if (!price || typeof price !== "number" || price < 0) {
        PriceCmpt = null;
      } else {
        if (produit.productType === "variants") {
          if (produit.variantId) {
            PriceCmpt = () => <Span className="price">{price}</Span>;
          }
          PriceCmpt = () => (
            <Span className="start_price">A partir de {price}</Span>
          );
        } else {
          if (!retailPrice) {
            PriceCmpt = () => <Span className="price">{price}</Span>;
          } else {
            PriceCmpt = () => (
              <div>
                {retailPrice && (
                  <Span className="retail-price">{retailPrice}</Span>
                )}
                {price && <Span className="price">{price}</Span>}
              </div>
            );
          }
        }
      }

      return (
        <React.Fragment>
          <PriceCmpt />
          {this.state.isDemandeSuccessed ? (
            <div className="demande-realisee">
              {t("product.request-fulfilled")}
            </div>
          ) : (
            <React.Fragment>
              {this.state.showPopupDemande && (
                <PopupDemande
                  t={t}
                  produit={this.props.produit}
                  handleOnClose={this.handleClosePopupDemande}
                />
              )}
              <button
                className="demande-prix"
                onClick={this.handleOpenPopupDemande}
              >
                {t("product.information-request")}
              </button>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }
    return <span className="vendu">{t("product.sold")}</span>;
  }
}

const StylePrice = styled.div`
  .demande-prix {
    background: #000;
    color: #fff;
    text-transform: uppercase;
    padding: 0;
    margin: 1.5rem 0;
    width: ${(props) => (props.theme.mobile ? "100%" : "300px")};
    height: 40px;
    line-height: 40px;
    font-family: Arial, sans-serif;
    text-align: center;
  }

  .demande-realisee {
    color: #97b7ac;
  }

  .vendu {
    font-weight: 700;
    text-align: center;
    font-size: 18px;
    display: block;
    text-transform: uppercase;
    color: #97b7ac;
  }

  .retail-price {
    text-decoration: line-through;

    &:after {
      content: " €";
      //margin-left: 0.1875rem;
      font-size: 85%;
    }
  }

  .price,
  .start_price {
    display: block;
    text-align: center;
    font-size: 26px;
    line-height: 30px;
  }
  .start_price {
    font-size: 19px;
  }

  .info {
    display: inline-block;
    text-align: center;
    color: #97b7ac;
    font-size: 22px;
    line-height: 30px;

    //font-style: italic;
  }
`;

const ShowPrice = ({ produit, info }) => {
  const { t } = useTranslation();

  return (
    <StylePrice>
      <Prix produit={produit} info={info} t={t} />
    </StylePrice>
  );
};

export default ShowPrice;
