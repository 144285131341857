import React from "react";
import styled from "styled-components";
import { Link, Match } from "@reach/router";


const Ul = styled.ul`
  display: block;
  text-align: center;
  list-style: none;
  padding: 0;

  li {
    display: inline-block;

    margin-right: 0.75rem;
    margin-bottom: 0.75rem;

    a {
      display: block;
      background: #f6f6f6;
      padding: 0.375rem 0.75rem;
      border-radius: 4px;
      text-decoration: none;
      color: #333;
      transition: color 0.15s ease;

      &:hover {
        color: #757575;
      }

      &.actif {
        font-weight: bold;
        font-style: italic;
      }
    }
  }
`;

const SubMenu = ({ datas }) => {
  return (
    <Ul>
      {datas &&
        datas.length > 0 &&
        datas.map((data, key) => (
          <li key={key}>
            <Match path={data.to}>
              {props => (
                <Link to={data.to} className={props.match ? "actif" : ""}>
                  {data.label}
                </Link>
              )}
            </Match>
          </li>
        ))}
    </Ul>
  );
};

export default SubMenu;
