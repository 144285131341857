import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  USER_LOGOUT,
  USER_LOAD_SUCCESS
} from "./actionTypes";

import User from "../../datas/User";

export function login(email, password) {
  return dispatch => {
    dispatch({
      type: LOGIN_REQUEST
    });

    return User.login(email, password)
      .then(result => {
        return dispatch({
          type: LOGIN_SUCCESS,
          user: result.user
        });
      })
      .catch(error => {
        return dispatch({
          type: LOGIN_ERROR,
          error: true,
          code: error.code,
          message: error.message
        });
      });
  };
}

export function logout() {
  return {
    type: USER_LOGOUT,
    payload: User.logout()
  };
}

export function load(user) {
  return {
    type: USER_LOAD_SUCCESS,
    user: user
  };
}
