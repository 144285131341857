import React from "react";
import styled from "styled-components";
import { Breadcrumbs, Title, SubMenu } from "../../template";
import Helmet from "react-helmet";
import pending from "../assets/images/loading.svg";


const Div = styled.div`
  display: flex;
  flex-direction: column;

  &:not(.columns) {
    max-width: ${props => (props.theme.mobile ? "100%" : "605px")};
    margin: 0 auto;
  }

  &.columns {
    flex-direction: row;
    &.reverse {
      flex-direction: row-reverse;
    }

    .main {
      flex: 1;
    }

    .second {
      width: 30%;
    }

    .main + .second,
    .second + .main {
      margin-left: 3rem;
    }

    &.reverse {
      .main + .second,
      .second + .main {
        margin-right: 3rem;
      }
    }

    ${props => {
      if (props.theme.mobile) {
        return {
          flexDirection: "column",
          "&.reverse": {
            flexDirection: "column"
          },
          margin: 0,
          ".second, .main": {
            width: "100%",
            marginLeft: "0 !important",
            marginRight: "0 !important"
          }
        };
      }
    }};
  }

  a {
    color: #757575;

    &.no-link {
      text-decoration: none;
      color: inherit;
    }

    &.link {
      font-style: italic;
      text-decoration: none;
      &.center {
        text-align: center;
        display: block;
      }

      display: block;
      color: #bbb7b1;

      &:before {
        content: "\\0021A6";
        margin-right: 0.75rem;
      }
    }
  }
`;

const Pending = styled.div`
  background: rgba(0, 0, 0, 0.5) url(${pending}) no-repeat center center;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Content = ({
  className,
  style,
  children,
  breadcrumbs,
  breadcrumbsTitle,
  title,
  submenu,
  head,
  loading,
  pending
}) => {
  return (
    <React.Fragment>
      <Helmet
        title={head && head.title ? head.title : title}
        meta={head && head.meta ? head.meta : []}
      />
      {breadcrumbs && (
        <Breadcrumbs
          values={breadcrumbs}
          title={breadcrumbsTitle ? breadcrumbsTitle : title}
        />
      )}
      {title && <Title>{title}</Title>}
      {submenu && <SubMenu datas={submenu} />}
      {loading ? (
        <div>Chargement en cours</div>
      ) : (
        <Div className={`content ${className ? className : ""}`} style={style}>
          {children}
        </Div>
      )}
      {pending && <Pending />}
    </React.Fragment>
  );
};

export default Content;
