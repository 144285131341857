import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";


const Ul = styled.ul`
  display: block;
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

  li {
    font-size: 13px;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    display: inline-block;
    color: #757575;

    ${props => {
      if (props.theme.mobile) {
        return {
          "&.hide_mobile": {
            display: "none"
          }
        };
      }
    }};

    a {
      color: #757575;
      text-decoration: none;

      &:hover {
        color: #333;
      }
    }

    &:after {
      color: #757575;
      content: "/";
      margin: 0 7px;
      top: 1px;
    }

    &:last-child {
      &:after {
        content: "";
        margin: 0;
      }
    }
  }
`;

const Breadcrumbs = ({ values, title }) => {
  return (
    <Ul>
      {/*<li className='breadcrumb'>*/}
      {/*<Link to='/'>Accueil</Link>*/}
      {/*</li>*/}
      {values.map((item, index) => (
        <li key={index} className={item.mobile ? "show_mobile" : "hide_mobile"}>
          <Link to={item.to}>{item.value}</Link>
        </li>
      ))}
      <li>{title}</li>
    </Ul>
  );
};

export default Breadcrumbs;
