import React from "react";
import { Router } from "@reach/router";
import Demande from "./plugins/demande/";
import Urls from "./innedit_src/plugins/urls";

import { Layout } from "./template";

import Index from "./pages/index";
import Catalogue from "./pages/catalogue";
import Contact from "./pages/contact";
import Newsletter from "./pages/newsletter";

import "./styles/normalize.css";
import "./styles/index.css";

const frontend = ({ contextData, ...others }) => {
  const nbByRowMobile = 2;
  const nbByRowDesktop = 3;
  const nbByRow = contextData.isMobileScreen ? nbByRowMobile : nbByRowDesktop;
  const hitsPerPage = 24;

  const pages = {
    stock: {
      title: "Catalogue",
      translationKey: "menu.products",
      facetFilters: [
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
        ["inStock:true"],
        ["isOnlineStore:true"],
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
    },
    vendus: {
      title: "Vendus",
      translationKey: "menu.products",
      facetFilters: [
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
        ["inStock:false"],
        ["hasInventory:true"],
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
    },
    consoles: {
      title: "Consoles",
      translationKey: "menu.products",
      facetFilters: [
        ["category:OBHoziPVyUFjW1ZuCsZD"],
        ["inStock:true"],
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
        ["isOnlineStore:true"],
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
    },
    sieges: {
      title: "Sièges",
      translationKey: "menu.products",
      facetFilters: [
        ["category:3SR7OpfSUx13ra6Z4XCI"],
        ["inStock:true"],
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
        ["isOnlineStore:true"],
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
    },
    tables: {
      title: "Tables",
      translationKey: "menu.products",
      facetFilters: [
        ["category:7VwO3tc27bau6tJikwON"],
        ["inStock:true"],
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
        ["isOnlineStore:true"],
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
    },
    meubles: {
      title: "Meubles",
      translationKey: "menu.products",
      facetFilters: [
        ["inStock:true"],
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
        ["category:18t3aKcW65BSaVeAOfWN"],
        ["isOnlineStore:true"],
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
    },
    miroirs: {
      title: "Miroirs",
      translationKey: "menu.products",
      facetFilters: [
        ["category:G58uTk1Pz701Osi5nJXc"],
        ["inStock:true"],
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
        ["isOnlineStore:true"],
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
    },
    luminaires: {
      title: "Luminaires",
      translationKey: "menu.products",
      facetFilters: [
        ["isOnlineStore:true"],
        ["inStock:true"],
        ["category:lFrXGaznlTZpeFmL3fs7"],
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
    },
    objets: {
      title: "Objets",
      translationKey: "menu.products",
      facetFilters: [
        ["isOnlineStore:true"],
        ["inStock:true"],
        ["category:5rNQ4V3HgTrl6VpyEXPQ"],
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
    },
    archi: {
      title: "éléments architecturaux",
      translationKey: "menu.products",
      facetFilters: [
        ["isOnlineStore:true"],
        ["inStock:true"],
        ["category:TDNONebDfhoAUOCaiNtX"],
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
    },
    tableaux: {
      title: "Tableaux",
      translationKey: "menu.products",
      facetFilters: [
        ["isOnlineStore:true"],
        ["inStock:true"],
        ["category:cGMvT1rGtLpag4n9UpsH"],
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
    },
    jardin: {
      title: "Jardin",
      translationKey: "menu.products",
      facetFilters: [
        ["isOnlineStore:true"],
        ["inStock:true"],
        ["collections:oXXcUUXnMPF8CY4KPyM0"],
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
    },
  };

  return (
    <Layout>
      <Router>
        <Index path="/" nbByRow={nbByRow} />

        <Catalogue path="stock" {...pages.stock} />
        <Catalogue path="stock/consoles" {...pages.consoles} />
        <Catalogue path="stock/sieges" {...pages.sieges} />
        <Catalogue path="stock/tables" {...pages.tables} />
        <Catalogue path="stock/meubles" {...pages.meubles} />
        <Catalogue path="stock/luminaires" {...pages.luminaires} />
        <Catalogue path="stock/miroirs" {...pages.miroirs} />
        <Catalogue path="stock/objets" {...pages.objets} />
        <Catalogue path="stock/elements-architecturaux" {...pages.archi} />
        <Catalogue path="stock/tableaux" {...pages.tableaux} />

        <Catalogue path="stock/jardin" {...pages.jardin} />
        <Catalogue path="vendus" {...pages.vendus} />

        <Contact path="contact">
          <Contact path={":id"} />
        </Contact>

        <Newsletter path="newsletters" />

        <Demande path="demande/:demandeId" />
        <Urls default />
      </Router>
    </Layout>
  );
};

export default frontend;
