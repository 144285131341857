import React, { Component } from "react";
import DefaultPopup from "../../../../templates/Popup";

class Popup extends Component {
  render() {
    const { produit } = this.props;

    const price = produit.shippingIncluded
      ? produit.price + produit.estimateShippingCost
      : produit.price;

    const buttons = [
      {
        onClick: this.props.handleOnClose,
        text: "Continuer votre sélection"
      },
      {
        primary: "true",
        to: "/panier",
        text: "Voir le panier"
      }
    ];

    return (
      <DefaultPopup
        title="Vous venez d'ajouter un produit dans votre panier"
        buttons={buttons}
        center
      >
        <div className="produit-item">
          <img
            className="thumbnail"
            alt={produit.name}
            src={`${process.env.REACT_APP_CDN_DOMAIN_NAME}/${
              produit.photo
            }?w=320&h=320&c=max&b=transparent&fm=png`}
          />
          <h2>{produit.shortname}</h2>
          <p>{produit.name}</p>
          <p className="infos">
            {produit.qty} x <span className="price">{price}</span>
          </p>
        </div>
      </DefaultPopup>
    );
  }
}

export default Popup;
