import React, { Component } from "react";
import styled from "styled-components";
import { Button } from "../../template";
import close from "../assets/images/close.svg";

const StyledPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: ${props => (props.theme.mobile ? "90%" : "605px")};
    margin-left: auto;
    margin-right: auto;
    margin-top: ${props =>
      props.theme.mobile ? "5vh" : "calc((100vh - 450px) / 2)"};
    box-sizing: border-box;
    background: white;
    //height: calc(100vh - 100px);
    height: ${props => (props.theme.mobile ? "90vh" : "450px")};
    position: relative;

    text-align: ${props => props.center && "center"};

    .content-footer {
      margin-bottom: 0.75rem;
      padding: 0 1.5rem;
    }
  }
`;

const StyledHeader = styled.div`
  padding: 0.75rem 1.5rem;
  background: #f6f6f6;
  position: relative;

  h1 {
    margin: 0;
    font-size: 18px;
  }

  .close {
    appearance: none !important;
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    position: absolute;
    top: 13px;
    right: 13px;
    .label {
      display: none;
    }
    cursor: pointer;

    .icon {
      mask: url(${close}) no-repeat 100% 100%;
      background-color: black;
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: top;
    }
  }
`;

const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1.5rem;

  .infos {
    font-style: italic;
  }
  .price {
    font-family: "Georgia", serif;
    &:after {
      content: " €";
    }
  }
`;

class Popup extends Component {
  stylePopup = null;
  styleHeader = null;
  styleBody = null;

  constructor(props, options) {
    super(props);

    if (options) {
      this.stylePopup = options.stylePopup;
      this.styleHeader = options.styleHeader;
      this.styleBody = options.styleBody;
    }

    this.divRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.documentElement.classList.toggle("is-clipped");
    this.divRef.current.style.top = window.scrollY + "px";
  }

  componentWillUnmount() {
    document.documentElement.classList.remove("is-clipped");
  }

  render() {
    const {
      title,
      picture,
      handleOnClose,
      buttons,
      children,
      ...others
    } = this.props;

    const Div = this.stylePopup ? styled(this.stylePopup)`` : StyledPopup;
    const Header = this.styleHeader ? styled(this.styleHeader)`` : StyledHeader;
    const Body = this.styleBody ? styled(this.styleBody)`` : StyledBody;

    return (
      <Div ref={this.divRef} {...others}>
        <div className="content">
          <Header>
            {picture && <img src={picture} alt="Présentation" />}
            {title && <h1>{title}</h1>}
            {handleOnClose && (
              <button onClick={handleOnClose} className="close">
                <span className="label">Fermer</span>
                <span className="icon" />
              </button>
            )}
          </Header>
          <Body>{children}</Body>
          {buttons && buttons.length > 0 && (
            <div className="content-footer">
              {buttons.map(({ text, ...others }, index) => (
                <Button key={index} {...others}>
                  {text}
                </Button>
              ))}
            </div>
          )}
        </div>
      </Div>
    );
  }
}

export default Popup;
