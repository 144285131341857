import React, { Component } from "react";
import styled from "styled-components";


const StyledTabs = styled.div`
  max-width: 66.67%;
  margin: 1.5rem auto 0 auto;

  dl {
    display: block;

    dt,
    dd {
      display: inline;
    }

    dt {
      margin-right: 1.5rem;
      font-weight: bold;
      white-space: nowrap;
    }

    dd {
      margin: 0;
      &:after {
        content: "";
        display: table;
      }
    }
  }

  ${props => {
    if (props.theme.mobile) {
      return {
        maxWidth: "100%",
        li: {
          width: "100%",
          textAlign: "center",
          marginBottom: "0.75rem"
        }
      };
    }
  }};

  .nav {
    margin: 0 0 1.5rem 0;
    padding: 0;
    list-style: none;
    text-align: center;
    li {
      //font-weight: bold;
      display: inline-block;
      cursor: pointer;
      margin-right: 1.5rem;
      font-size: 16px;
      text-transform: uppercase;
      color: #d6d4d4;
      font-style: italic;

      &.active {
        border-bottom: 3px solid #444;
        color: #444;
        font-style: normal;
      }
    }
  }

  .tab {
    display: none;
    margin-bottom: 1.5rem;

    &.visible {
      display: block;
    }
  }
`;

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: this.props.initialTab ? this.props.initialTab : 0
    };

    this.changeTabIndex = this.changeTabIndex.bind(this);
  }

  changeTabIndex = event => {
    if (!!!this.props.showOnlyOneTab) {
      const index = event.currentTarget.getAttribute("data-index");
      this.setState({
        tabIndex: index
      });
    }
  };

  render() {
    const { values } = this.props;

    return (
      <StyledTabs>
        <ul className="nav">
          {values.map((value, index) => (
            <li
              key={index}
              className={
                parseInt(this.state.tabIndex, 10) === parseInt(index, 10)
                  ? "active"
                  : ""
              }
              onClick={this.changeTabIndex}
              data-index={index}
            >
              {value.title}
            </li>
          ))}
        </ul>
        {!!this.showOnlyOneTab && (
          <div
            className={"tab visible"}
            dangerouslySetInnerHTML={{
              __html: values[this.state.tabIndex].content
            }}
          />
        )}
        {!!!this.showOnlyOneTab &&
          values.map((value, index) => (
            <div
              key={index}
              className={
                parseInt(this.state.tabIndex, 10) === parseInt(index, 10)
                  ? "tab visible"
                  : "tab"
              }
              dangerouslySetInnerHTML={{ __html: value.content }}
            />
          ))}
      </StyledTabs>
    );
  }
}

export default Tabs;
