import React, { Component } from "react";
import Item from "./Item";
import { Content } from "../../../template";
import DemandeData from "../../datas/Demande";

class Demande extends Component {
  view = Item;
  state = {
    demandeId: null,
    demande: null,
    error: false,
    message: null,
    mustBeLoaded: false,
    unsubscribe: undefined
  };

  constructor(props) {
    super(props);

    this.loadDemande = this.loadDemande.bind(this);
  }

  componentDidMount() {
    if (this.state.mustBeLoaded) {
      this.loadDemande(this.state.demandeId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoaded) {
      this.loadDemande(this.state.demandeId);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.demandeId &&
      (!state.demandeId || state.demandeId !== props.demandeId)
    ) {
      state.demandeId = props.demandeId;
      state.mustBeLoaded = true;
    }

    return state;
  }

  loadDemande(demandeId) {
    const _this = this;
    if (this.state.unsubscribe) this.state.unsubscribe();

    const unsubscribe = DemandeData.watch(demandeId, document => {
      if (document.exists && !document.get("deleted")) {
        _this.setState({
          demande: document
        });
      } else {
        _this.setState({
          error: true,
          message: "La demande n'existe pas ou a été supprimée"
        });
      }
    });

    this.setState({
      mustBeLoaded: false,
      unsubscribe: unsubscribe
    });
  }

  componentWillUnmount() {
    if (this.state.unsubscribe) this.state.unsubscribe();
  }

  render() {
    const ViewItem = this.view;

    if (this.state.mustBeLoaded || (!this.state.demande && !this.state.error)) {
      return <Content loading={true} />;
    }

    const title = this.state.demande ? `Demande` : "Erreur de chargement";

    return (
      <Content title={title}>
        {this.state.error ? (
          <p> {this.state.message}</p>
        ) : (
          <ViewItem
            demande={this.state.demande}
            loading={this.state.mustBeLoaded}
            title={title}
          />
        )}
      </Content>
    );
  }
}

export default Demande;
