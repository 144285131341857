import { getFirestore } from "../config/functions";
import { client } from "../plugins/algolia";

class Produit {
  /**
   * Recherche d'un produit par son identifiant
   *
   * @param id
   * @returns {Promise<firebase.firestore.DocumentSnapshot>}
   */
  static findById(id) {
    return getFirestore()
      .collection("produits")
      .doc(id)
      .get()
      .then(documentSnapshot => {
        if (documentSnapshot.exists && !documentSnapshot.get("deleted")) {
          return documentSnapshot;
        }
        throw new Error("Le produit n'existe pas ou a été supprimé");
      });
  }

  /**
   * Recherche d'un produit par le biais du pathname
   *
   * @param pathname
   * @returns {Promise<firebase.firestore.QuerySnapshot>|*}
   */
  static findByPathname(pathname) {
    return getFirestore()
      .collection("produits")
      .where("boutique", "==", process.env.REACT_APP_ID_BOUTIQUE)
      .where("pathname", "==", pathname)
      .where("deleted", "==", false)
      .get()
      .then(snapshotQuery => {
        if (!snapshotQuery.empty && snapshotQuery.size === 1) {
          // Il y a un resultat
          return snapshotQuery.docs[0];
        } else {
          throw new Error("Le produit n'existe pas ou a été supprimé");
        }
      });
  }

  static search(options) {
    const database =
      options && options.database ? options.database : "firestore";

    const q = options && options.q ? options.q : undefined;
    const nbParPage = options && options.nbParPage ? options.nbParPage : 40;
    const pageIndex = options && options.pageIndex ? options.pageIndex : 0;
    const facetFilters =
      options && options.facetFilters ? options.facetFilters : {};
    const params = options && options.params ? options.params : {};

    switch (database) {
      case "algolia":
        return client
          .initIndex(
            `produits_${process.env.REACT_APP_FIREBASE_PROJECT_ID.toUpperCase()}`
          )
          .search(q, {
            hitsPerPage: nbParPage,
            page: pageIndex,
            facetFilters: facetFilters,
            ...params
          });
      case "firestore":
      default:
        return getFirestore()
          .collection("produits")
          .limit(nbParPage)
          .get();
    }
  }
}

export default Produit;
