import {
  USER_UPDATE_FIELDS,
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
  USER_LOAD_ERROR,
  USER_LOGOUT,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR
} from "./actionTypes";

const initialState = {
  contact_firstname: "",
  contact_lastname: "",
  contact_name: "",
  contact_email: "",
  contact_phone: "",
  contact_entreprise: "",
  contact_zip: "",
  contact_city: "",
  contact_country: "",
  isLoading: false,
  isLogged: false,
  user: null
};


export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_UPDATE_FIELDS: {
      const fields = [
        "contact_firstname",
        "contact_lastname",
        "contact_name",
        "contact_email",
        "contact_phone",
        "contact_entreprise",
        "contact_zip",
        "contact_city",
        "contact_country"
        // "contact_newsletter"
      ];
      const newState = Object.assign({}, state);
      Object.keys(action.data).forEach(key => {
        if (fields.includes(key)) {
          newState[key] = action.data[key];
        }
      });

      return newState;
    }

    case LOGIN_REQUEST:
    case USER_LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLogged: false,
        user: null
      };
    }

    case LOGIN_SUCCESS:
    case USER_LOAD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLogged: true,
        user: action.user
      };
    }

    case LOGIN_ERROR:
    case USER_LOAD_ERROR:
    case USER_LOGOUT: {
      return {
        ...state,
        isLoading: false,
        isLogged: false,
        user: null
      };
    }

    default:
  }

  return state;
}
