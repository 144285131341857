import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Loader, Button, Field } from "../../../template";

class Form extends Component {
  state = {
    success: false,
    pending: false,
    data: null,
    message: ""
  };

  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.data) {
      state.data = {};
      Object.keys(props.datas).forEach(key => {
        state.data[key] = props.datas[key].value ? props.datas[key].value : "";
      });
    }
    if (props.user) {
      if (!state.data.contact_firstname) {
        state.data.contact_firstname = props.user.contact_firstname
          ? props.user.contact_firstname
          : props.datas.contact_firstname && props.datas.contact_firstname.value
          ? props.datas.contact_firstname.value
          : "";
      }

      if (!state.data.contact_lastname) {
        state.data.contact_lastname = props.user.contact_lastname
          ? props.user.contact_lastname
          : props.datas.contact_lastname && props.datas.contact_lastname.value
          ? props.datas.contact_lastname.value
          : "";
      }

      if (!state.data.contact_name) {
        state.data.contact_name = props.user.contact_name
          ? props.user.contact_name
          : props.datas.contact_name && props.datas.contact_name.value
          ? props.datas.contact_name.value
          : "";
      }

      if (!state.data.contact_email) {
        state.data.contact_email = props.user.contact_email
          ? props.user.contact_email
          : props.datas.contact_email && props.datas.contact_email.value
          ? props.datas.contact_email.value
          : "";
      }

      if (!state.data.contact_phone) {
        state.data.contact_phone = props.user.contact_phone
          ? props.user.contact_phone
          : props.datas.contact_phone && props.datas.contact_phone.value
          ? props.datas.contact_phone.value
          : "";
      }

      if (!state.data.contact_entreprise) {
        state.data.contact_entreprise = props.user.contact_entreprise
          ? props.user.contact_entreprise
          : props.datas.contact_entreprise &&
            props.datas.contact_entreprise.value
          ? props.datas.contact_entreprise.value
          : "";
      }

      if (!state.data.contact_zip) {
        state.data.contact_zip = props.user.contact_zip
          ? props.user.contact_zip
          : props.datas.contact_zip && props.datas.contact_zip.value
          ? props.datas.contact_zip.value
          : "";
      }

      if (!state.data.contact_city) {
        state.data.contact_city = props.user.contact_city
          ? props.user.contact_city
          : props.datas.contact_city && props.datas.contact_city.value
          ? props.datas.contact_city.value
          : "";
      }

      if (!state.data.contact_country) {
        state.data.contact_country = props.user.contact_country
          ? props.user.contact_country
          : props.datas.contact_country && props.datas.contact_country.value
          ? props.datas.contact_country.value
          : "";
      }
    }

    return state;
  }

  handleOnChange(event) {
    let value;
    const name = event.currentTarget.name;

    switch (event.currentTarget.type) {
      case "checkbox":
        value = event.currentTarget.checked;
        break;

      case "number":
        value =
          Number(event.currentTarget.value) > 0
            ? Number(event.currentTarget.value)
            : null;
        break;

      default:
        value = event.currentTarget.value;
    }

    let data = { ...this.state.data, [name]: value };
    this.setState({ data });
  }

  handleOnSubmit(event) {
    event.preventDefault();

    if (!this.state.pending) {
      this.setState(
        {
          pending: true
        },
        () => {
          if (
            this.props.isOnSubmitPromise === undefined ||
            this.props.isOnSubmitPromise
          ) {
            this.props
              .onSubmit(this.state.data)
              .then(action => {
                if (action) {
                  if (!action.error) {
                    this.setState({
                      pending: false,
                      success: true,
                      message: "Votre formulaire a bien été envoyée"
                    });
                  } else {
                    this.setState({
                      pending: false,
                      success: false,
                      message: action.message
                    });
                  }
                }
              })
              .catch(error => {
                console.error("handleOnSubmit onSubmit", error);
              });
          } else {
            this.props.onSubmit(this.state.data);
          }
        }
      );
    }
  }

  render() {
    const {
      datas,
      children,
      className,
      textSubmit,
      pending,
      success
    } = this.props;
    const Pending = pending;
    const Success = success;

    return (
      <form
        onSubmit={this.handleOnSubmit}
        style={{ display: "flex", flexDirection: "column", flex: 1, margin: 0 }}
        className={className}
      >
        {this.state.pending && pending ? (
          <Pending />
        ) : this.state.success && success ? (
          <Success email={this.state.data.contact_email} />
        ) : (
          <React.Fragment>
            {this.state.pending && <Loader />}
            {children}
            <div
              className="form-content"
              style={{
                display: "flex",
                flexWrap: "wrap",
                flex: 1,
                alignContent: "flex-start"
              }}
            >
              {Object.keys(datas).map(key => (
                <Field
                  key={`form_${key}`}
                  name={key}
                  {...datas[key]}
                  value={this.state.data[key]}
                  onChange={this.handleOnChange}
                />
              ))}
            </div>
            <div
              style={{ width: "100%", display: "block", textAlign: "center" }}
            >
              <Button primary>
                {textSubmit ? textSubmit : "Valider le formulaire"}
              </Button>
              {this.state.message && (
                <span className="message">{this.state.message}</span>
              )}
            </div>
          </React.Fragment>
        )}
      </form>
    );
  }
}

Form.propTypes = {
  datas: PropTypes.object.isRequired
};

const mapStateToProps = globalState => {
  return {
    user: globalState.user
  };
};

export default connect(mapStateToProps)(Form);
