import React from "react";
import styled from "styled-components";

const H1 = styled.h1`
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)};
`;

const Title = ({ children, ...others }) => <H1 {...others}>{children}</H1>;

export default Title;
