import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import Produit from "../../datas/Produit";
import { initProduit } from "../../functions/Demande";
import bulle from "../../assets/images/bulle.png";
import bulleReponse from "../../assets/images/bulle-reponse.png";

const Li = styled.li`
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0;
  border-bottom: 1px solid #f6f6f6;

  .demande {
    flex: 1;
  }

  .conversation {
    margin-top: 1.5rem;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-self: center;

    .message {
      max-width: 70%;
      position: relative;
      background: #e5e5ea;
      border-radius: 15px;
      padding: 0.375rem 0.75rem;
      margin-bottom: 0.75rem;
      align-self: flex-start;
      &:after {
        content: "";
        background: url(${bulle}) no-repeat;
        width: 19px;
        height: 15px;
        display: block;
        position: absolute;
        left: -1px;
        right: auto;
        bottom: -8px;
      }
    }
    .message-reponse {
      max-width: 70%;
      position: relative;
      text-align: right;
      background: #087df8;
      color: #fff;
      border-radius: 15px;
      padding: 0.375rem 0.75rem;
      align-self: flex-end;
      &:after {
        content: "";
        background: url(${bulleReponse}) no-repeat;
        width: 19px;
        height: 15px;
        display: block;
        position: absolute;
        left: auto;
        right: -6px;
        bottom: -8px;
      }
    }
  }

  .produit {
    flex: 1;
    display: flex;
    flex-direction: row;

    .infos {
      flex: 1;

      .name {
        font-size: 14px;
        font-weight: normal;
        display: block;
        margin: 0;
      }

      ul {
        list-style: none;
        font-size: 13px;
        margin: 0;
        padding: 0;

        li {
          margin-bottom: 0.75rem;
          > ul > li {
            margin: 0;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .last-column {
      text-align: right;
    }

    .prix {
      .prix-infos {
        display: block;
      }

      .prix-barre {
        text-decoration: line-through;
      }

      .inventaire {
        display: block;
      }
    }
  }

  .button {
    margin-top: 0.375rem;
    display: inline-block;
    //background: #bd0b02;
    background: #666666;
    padding: 0.375rem 0.75rem;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    font-size: 90%;

    &:hover {
      background: #333;
    }
  }

  .produit-photo {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
`;

class Request extends Component {
  state = {
    produit: undefined,
    produitId: undefined,
    mustBeLoaded: false
  };

  constructor(props) {
    super(props);

    this.loadProduit = this.loadProduit.bind(this);
  }

  componentDidMount() {
    if (this.state.mustBeLoaded) {
      this.loadProduit(this.state.demandeId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoaded) {
      this.loadProduit(this.state.demandeId);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.item &&
      props.item.produit_id &&
      (!state.produitId || state.produitId !== props.item.produit_id)
    ) {
      state.produitId = props.item.produit_id;
      state.mustBeLoaded = true;
    }

    return state;
  }

  loadProduit() {
    const _this = this;
    this.setState({
      mustBeLoaded: false
    });

    Produit.findById(this.state.produitId).then(document => {
      _this.setState(
        {
          produit: initProduit(
            {
              ...document.data(),
              prixDeVente: _this.props.item.produit_prixDeVente,
              prixUnite: _this.props.item.produit_unite,
              prixBarre: _this.props.item.produit_prixBarre
            },
            this.props.deliveryCostVolume
          )
        },
        () => {
          this.props.handleSetProduit(this.props.index, this.state.produit);
        }
      );
    });
  }

  render() {
    const {
      item: { produit_photo, produit_prixDeVente, produit_prixBarre, content }
    } = this.props;

    return (
      <Li
        isProduit={Boolean(this.props.item.produit_id)}
        isBuy={this.props.item.isBuy}
      >
        {this.state.produit && (
          <div className="produit">
            {produit_photo && (
              <img
                className="produit-photo"
                height={80}
                width={80}
                alt={this.state.produit.name}
                src={`${
                  process.env.REACT_APP_CDN_DOMAIN_NAME
                }/${produit_photo}`}
              />
            )}
            <div className="demande">
              <div className="produit">
                <div className="infos">
                  <span className="name">
                    <Link to={`/${this.state.produit.pathname}`}>
                      {this.state.produit.name}
                    </Link>
                  </span>
                  <ul>
                    <li>
                      <ul>
                        {this.state.produit.diametre ? (
                          <li>
                            Dimensions : {this.state.produit.diametre} cm de
                            diamètre sur {this.state.produit.height} cm (
                            {this.state.produit.volume} m<sup>3</sup>)
                          </li>
                        ) : (
                          <li>
                            Dimensions : {this.state.produit.depth} x{" "}
                            {this.state.produit.width} sur{" "}
                            {this.state.produit.height} cm (
                            {this.state.produit.volume} m<sup>3</sup>)
                          </li>
                        )}
                        {this.state.produit.min > 1 && (
                          <li>Quantité minimale : {this.state.produit.min}</li>
                        )}
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="last-column">
                  {this.state.produit.isSold ? (
                    <div className="prix">{this.state.produit.stock}</div>
                  ) : (
                    <React.Fragment>
                      <div className="prix">
                        {produit_prixDeVente}€ {this.state.produit.unite}
                        {produit_prixBarre && (
                          <span className="prix-infos">
                            (
                            <span className="prix-barre">
                              {produit_prixBarre}
                            </span>
                            € {this.state.produit.remise})
                          </span>
                        )}
                      </div>
                      <button
                        onClick={e =>
                          this.props.handleToggleIsBuy(e, this.props.index)
                        }
                      >
                        {this.props.item.isBuy ? "Retirer" : "Ajouter"}
                      </button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {content && (
          <div className="conversation">
            <div className="message">{content}</div>
            {this.props.item.reponse && (
              <div className="message-reponse">{this.props.item.reponse}</div>
            )}
          </div>
        )}
      </Li>
    );
  }
}

export default Request;
