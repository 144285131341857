import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FeatureImage from "./Modele/FeatureImage";
import Informations from "./Modele/Informations";
import Devis from "./Modele/Devis";
import Buy from "./Modele/Buy";
import { loadVariants } from "../../../plugins/catalogue/actions";
import { Breadcrumbs, Tabs } from "../../../../template";
// import { withTemplate } from "../../../../innedit_src/store/TemplateProvider";

class Modele extends Component {
  state = {
    produit: this.props.defaultItem
  };

  componentDidMount() {
    if (
      this.state.produit &&
      this.state.produit.productType === "variants" &&
      this.state.produit.meta &&
      this.state.produit.meta.ref
    ) {
      this.props.loadVariants(this.state.produit.meta.ref).then(action => {
        if (!action.error) {
          const produit = Object.assign({}, this.props.defaultItem);
          produit.variants = action.variants;

          this.setState({
            produit: produit
          });
        } else {
          console.error(action.message);
        }
      });
    }
  }

  render() {
    const data = this.state.produit;

    let photo = "";
    if (data.photos && data.photos.length > 0) {
      photo = `${process.env.REACT_APP_CDN_DOMAIN_NAME}/${
        data.photos[0]
      }?w=908&fm=png`;
    }

    let dimensions = ``;
    if (data.width) {
      dimensions += `
        <dt>Largeur</dt>
        <dd>${data.width} cm</dd>
    `;
    }
    if (data.depth) {
      dimensions += `
        <dt>Profondeur</dt>
        <dd>${data.depth} cm</dd>
    `;
    }
    if (data.height) {
      dimensions += `
        <dt>Hauteur</dt>
        <dd>${data.height} cm</dd>
    `;
    }
    if (data.diametre) {
      dimensions += `
        <dt>Diamètre</dt>
        <dd>${data.diametre} cm</dd>
    `;
    }
    if (data.weight) {
      dimensions += `
        <dt>Poids</dt>
        <dd>${data.weight} kg</dd>
    `;
    }
    if (data.fragile) {
      dimensions += `
        <dt>Fragile</dt>
        <dd>Produit fragile</dd>
    `;
    }
    if (data.dimensions && data.dimensions.length > 0) {
      data.dimensions.forEach(dimension => {
        if (dimension.libelle && dimension.valeur) {
          dimensions += `<dt>${dimension.libelle}</dt>`;
          dimensions += `<dd>${dimension.valeur} ${dimension.unite}</dd>`;
        }
      });
    }

    let tabs = data.tabs ? data.tabs : [];
    if (dimensions) {
      tabs.unshift({
        title: "Dimensions",
        content: `<dl>${dimensions}</dl>`
      });
    }

    const stock = `
        <p>
        Pour vous permettre d'obtenir plus rapidement un exemplaire de ce modèle, il est possible qu'un autre exemplaire soit fabriqué. Il reprend les mêmes caractéristiques du modèle et quasiment identique en tout point.
        </p>
        <p>Cependant, il est possible de certaines différences soient visibles comme des détails ou des assemblages différents.</p>
        <p>De manière générale, les caractéristiques qui décrivent le produit sont toujours respectées. Les éléments qui peuvent variés sont les suivants : </p>
        <ol>
            <li>L'assemblage des portes (charnières, épaisseurs, rendus des tôles, etc.)</li>
            <li>L'assemblage des tiroirs : poignets, patine</li>
            <li>La finition des éléments de fermeture (loquets et autres quincalleries...)</li>
        </ol>
        <p>Cette liste n'est pas exhaustive.</p>
        <p>Les 3 raisons principales de ces changements sont :</p>
        <ul>
            <li>Les produits sont fabriqués artisanalement</li>
            <li>Nous améliorons le rendu et la conception du modèle</li>
            <li>Les matériaux utilisés peuvent provenir d'un second emploi</li>
        </ul>
        <br/>
        <p>Si par envie vous souhaitez voir le rendu exact du produit, il vous est toujours possible de nous demandé des photos de l'exemplaire que vous avez commandé. Nous nous ferons un plaisir de vous les envoyer.</p>
    `;

    tabs.push({
      title: "Fabrication & Stock",
      content: `<dl>${stock}</dl>`
    });

    let values = [];
    switch (data.category) {
      case "HsUXDbfb8tLbIac5a6nA":
        values.push({
          mobile: true,
          to: "/tables",
          value: "Tables"
        });
        break;

      case "Ah48tqDv6TaMyFwnTGK0":
        values.push({
          mobile: true,
          to: "/miroirs",
          value: "Miroirs"
        });
        break;

      case "plQnetzWo1MP9NPAJpYS":
        values.push({
          mobile: true,
          to: "/meubles",
          value: "Meubles"
        });
        break;

      default:
    }

    return (
      <div>
        <Breadcrumbs
          values={values}
          title={data.shortname ? data.shortname : data.name}
        />
        <FeatureImage src={photo} alt={data.name} />
        <h1 style={{ textAlign: "center" }}>
          {data.shortname ? data.shortname : data.name}
        </h1>
        <Buy produit={data} />

        <Informations data={data} />
        <Tabs values={tabs} />
        <Devis />
      </div>
    );
  }
}

Modele.propTypes = {
  defaultItem: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    loadVariants: ref => dispatch(loadVariants(ref))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modele);
