import React, { Component } from "react";
import styled from "styled-components";
import loader from "../../../../assets/images/picture.svg";

const Div = styled.div`
  max-width: ${props => (props.theme.mobile ? "100%" : " 66.66667%")};
  margin: 0 auto;
  text-align: center;

  &.vertical {
    max-width: ${props => (props.theme.mobile ? "100%" : " 25%")};
  }
`;

class FeatureImage extends Component {
  constructor(props) {
    super(props);

    this.imageRef = React.createRef();
    this.loadImage = this.loadImage.bind(this);
  }

  loadImage(src) {
    const _this = this;
    this.imageRef.current.src = loader;
    const image = new Image();
    image.onload = function(e) {
      if (_this.imageRef && _this.imageRef.current) {
        _this.imageRef.current.src = src;
      }
    };
    image.src = src;
  }

  render() {
    const { src, alt } = this.props;
    return (
      <Div>
        <img ref={this.imageRef} src={src} alt={alt} />
      </Div>
    );
  }
}

export default FeatureImage;
