import React, { Component } from "react";
import Request from "./Request";
import { Form } from "../../template";
import { getInfosFromRequests } from "../../innedit_src/functions/Demande";
import Action from "../../innedit_src/datas/Action";

class DemandeItem extends Component {
  state = {
    demandeId: null,
    requests: [],
    actionInProgress: false,
    actionSuccessed: false,
    datasContact: {
      contact_firstname: {
        label: "Prénom",
        type: "text",
        required: true
      },
      contact_lastname: {
        label: "Nom",
        type: "text",
        required: true
      },
      contact_phone: {
        label: "Téléphone",
        type: "text"
      },
      contact_email: {
        label: "Adresse e-mail",
        required: true,
        type: "email"
      },
      contact_zip: {
        label: "Code postal",
        type: "text"
      },
      contact_entreprise: {
        label: "Entreprise",
        type: "text"
      },
      contact_city: {
        label: "Ville",
        type: "text"
      },
      contact_country: {
        label: "Pays",
        type: "text"
      },
      content: {
        label: "Message",
        component: "textarea",
        componentStyle: { minHeight: "180px" },
        required: true,
        style: { width: "100%" }
      }
    }
  };

  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSetProduit = this.handleSetProduit.bind(this);
    this.handleToggleIsBuy = this.handleToggleIsBuy.bind(this);
    this.handleSubmitMoreInfos = this.handleSubmitMoreInfos.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.demande &&
      (!state.demandeId || state.demandeId !== props.demande.id)
    ) {
      const demande = props.demande.data();
      state.demandeId = props.demande.id;
      if (demande.contact_lastname) {
        state.datasContact.contact_lastname.value = demande.contact_lastname;
      }
      if (demande.contact_firstname) {
        state.datasContact.contact_firstname.value = demande.contact_firstname;
      }
      if (demande.contact_email) {
        state.datasContact.contact_email.value = demande.contact_email;
      }
      if (demande.contact_phone) {
        state.datasContact.contact_phone.value = demande.contact_phone;
      }
      if (demande.contact_zip) {
        state.datasContact.contact_zip.value = demande.contact_zip;
      }
      if (demande.contact_city) {
        state.datasContact.contact_city.value = demande.contact_city;
      }
      if (demande.contact_country) {
        state.datasContact.contact_country.value = demande.contact_country;
      }
      if (demande.contact_entreprise) {
        state.datasContact.contact_entreprise.value =
          demande.contact_entreprise;
      }

      if (demande.requests) {
        state.requests = [];
        demande.requests.forEach(request => {
          if (request.type === "request" || request.type === "contact") {
            if (request.produit_id) {
              state.requests.push({
                ...request,
                isBuy: false
              });
            } else {
              state.requests.push(request);
            }
          }
        });
      }
    }

    return state;
  }

  handleOnChange(e) {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    this.setState({
      [name]: value
    });
  }

  handleSetProduit(index, produit) {
    const isBuy = produit.isBuy;
    delete produit.isBuy;

    this.setState(oldState => {
      oldState.requests[index].produit = produit;
      oldState.requests[index].isBuy = isBuy;

      return oldState;
    });
  }

  handleToggleIsBuy(e, index) {
    this.setState(oldState => {
      oldState.requests[index].isBuy = !oldState.requests[index].isBuy;
      return oldState;
    });
  }

  handleSubmitMoreInfos(datas) {
    datas.demande_id = this.state.demandeId;
    const products = [];
    this.state.requests.forEach(request => {
      if (request.isBuy) {
        products.push(request.produit_id);
      }
    });
    if (products.length > 0) {
      datas.selectedProducts = products;
    }

    return Action.create(datas, "demande-update");
  }

  render() {
    const { demande } = this.props;

    const data = demande.data();
    const infos = getInfosFromRequests(this.state.requests);

    return (
      <React.Fragment>
        {data.reponse && (
          <div
            dangerouslySetInnerHTML={{
              __html: data.reponse.replace(/\n/gi, "<br/>")
            }}
          />
        )}

        <ul
          className="requests"
          style={{ margin: "1.5rem 0 0 0", padding: 0, listStyle: "none" }}
        >
          {this.state.requests &&
            this.state.requests.map((request, index) => (
              <Request
                key={index}
                index={index}
                item={request}
                handleSetProduit={this.handleSetProduit}
                handleToggleIsBuy={this.handleToggleIsBuy}
                deliveryCostVolume={data.deliveryCostVolume}
              />
            ))}
        </ul>

        {infos.nbProduits > 0 && (
          <div style={{ textAlign: "right", marginTop: "1.5rem" }}>
            Total : {infos.total} € pour un volume de {infos.volume} m
            <sup>3</sup>
          </div>
        )}

        <h3 style={{ marginTop: "3rem" }}>Besoin de plus d'informations ?</h3>
        {infos.nbProduits > 0 && (
          <p style={{ textAlign: "center" }}>
            Sélectionnez le ou les produits qui vous intéresse-nt et cliquez sur
            "En savoir plus"
          </p>
        )}

        <Form
          onSubmit={this.handleSubmitMoreInfos}
          datas={this.state.datasContact}
          className="main"
          textSubmit="En savoir plus"
        />
        {infos.nbProduits > 0 && (
          <p style={{ textAlign: "center", width: "100%" }}>
            {infos.nbProduits > 1
              ? `${infos.nbProduits} produits`
              : "1 produit"}{" "}
            d'un montant total de {infos.total} € (hors livraison)
          </p>
        )}
      </React.Fragment>
    );
  }
}

export default DemandeItem;
