import {
  IS_MOBILE,
  IS_WEBAPP,
  ALGOLIA_LOADING,
  ALGOLIA_SUCCESS,
  ALGOLIA_ERROR
} from "./actionTypes";

const initialState = {
  mobile: false,
  webapp: false,
  loading: false,
  error: null
};


export default function pageReducer(state = initialState, action) {
  switch (action.type) {
    case IS_MOBILE:
      return { ...state, mobile: action.test };

    case IS_WEBAPP:
      return { ...state, webapp: action.test };

    case ALGOLIA_LOADING:
      return { ...state, loading: true, error: false };

    case ALGOLIA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ALGOLIA_ERROR:
      return { ...state, loading: false, error: action.error };

    default:
  }

  return state;
}
