import React, { Component } from "react";
import styled from "styled-components";
import {
  convertFromRaw,
  Editor,
  EditorState,
  CompositeDecorator
} from "draft-js";
import slug from "slug";

const Div = styled.div`
  &.above-center {
    display: flex;
    flex-direction: column;

    .medias {
      max-width: 100%;
      align-self: center;
    }
  }

  &.below-center {
    display: flex;
    flex-direction: column-reverse;

    .medias {
      max-width: 100%;
      align-self: center;
      margin-top: 1.5rem;
    }
  }

  &.beside-text-left {
    .medias {
      float: left;
      max-width: 50%;
      margin-right: 1.5rem;
    }
  }

  &.beside-text-right {
    .medias {
      float: right;
      max-width: 50%;
      margin-left: 1.5rem;
    }
  }

  &.beside-text-left-centered {
    display: flex;
    flex-direction: row;
    .medias {
      margin-right: 1.5rem;
      max-width: 50%;
    }
  }

  &.beside-text-right-centered {
    display: flex;
    flex-direction: row-reverse;
    .medias {
      margin-left: 1.5rem;
      max-width: 50%;
    }
  }

  .medias {
    align-content: start;
    display: grid;
    grid-gap: 0.75rem;

    &.mediasNbColumns-na,
    &.mediasNbColumns-1 {
      grid-template-columns: auto;
      grid-template-rows: auto;
    }
    &.mediasNbColumns-2 {
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
    }
    &.mediasNbColumns-3 {
      grid-template-columns: auto auto auto;
      grid-template-rows: auto auto auto;
    }
    &.mediasNbColumns-4 {
      grid-template-columns: auto auto auto auto;
      grid-template-rows: auto auto auto auto;
    }
    &.mediasNbColumns-5 {
      grid-template-columns: auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto;
    }
    &.mediasNbColumns-6 {
      grid-template-columns: auto auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto auto;
    }
  }
`;

const HeaderType = ({ type, alignement = "left", children }) => {
  if (!alignement || alignement === "n/a") {
    alignement = "left";
  }
  switch (type) {
    case "h1":
      return <h1 style={{ textAlign: alignement }}>{children}</h1>;

    case "h3":
      return <h3 style={{ textAlign: alignement }}>{children}</h3>;

    case "h4":
      return <h4 style={{ textAlign: alignement }}>{children}</h4>;

    case "h5":
      return <h5 style={{ textAlign: alignement }}>{children}</h5>;

    case "h6":
      return <h6 style={{ textAlign: alignement }}>{children}</h6>;

    case "hidden":
      return null;

    case "h2":
    default:
      return <h2 style={{ textAlign: alignement }}>{children}</h2>;
  }
};

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}
const Link = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return <a href={url}>{props.children}</a>;
};

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link
  }
]);

class Content extends Component {
  render() {
    const { item, classNames = [] } = this.props;

    const data = item.data();

    if (data.mediasPosition) {
      classNames.push(data.mediasPosition);
    }

    return (
      <div className="sandiwch-cms-content">
        {data.header && (
          <HeaderType type={data.headerType} alignement={data.headerAlignement}>
            {data.header}
          </HeaderType>
        )}
        <Div className={classNames.join(" ")}>
          {data.medias && data.medias.length > 0 && (
            <div
              className={`medias mediasNbColumns-${slug(data.mediasNbColumns)}`}
            >
              {data.medias.map((media, index) => (
                <img
                  src={`${process.env.REACT_APP_CDN_DOMAIN_NAME}/${media.fullPath}`}
                  alt={media.title}
                  key={index}
                />
              ))}
            </div>
          )}
          {data.text && (
            <div className="text">
              <Editor
                editorState={EditorState.createWithContent(
                  convertFromRaw(data.text),
                  decorator
                )}
                readOnly={true}
              />
            </div>
          )}
        </Div>
      </div>
    );
  }
}
export default Content;
