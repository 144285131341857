import React, { Component } from "react";
import styled from "styled-components";

const StyledPhotos = styled.div`
  margin-top: 1.5rem;
  margin-right: ${props => (props.theme.mobile ? 0 : "1.5rem")};
  flex: 5;

  .aspect-ratio--square {
    position: relative;
    display: block;
    //background: #f7f7f7;
    padding: 0;

    &:before {
      z-index: 1;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      // border: 1px solid rgba(195, 207, 216, 0.3);
    }

    width: 100%;
    padding-bottom: 100%;
    flex-shrink: 0;
    overflow: hidden;

    img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }

  .main-picture {
    margin-bottom: 1.5rem;
  }

  .miniatures {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;
      background: #f7f7f7;
      display: inline-block;
      margin: 0;
      width: 10%;
      //width: 50px;
      //height: 50px;
      border: 1px solid rgba(195, 207, 216, 0.3);
      margin-right: 2.5%;
      margin-bottom: 2.5%;
      box-sizing: border-box;

      img {
        display: block;
      }
    }
  }
`;

class Photos extends Component {
  constructor(props) {
    super(props);

    // On supprime la premiere photo
    const photos = this.props.datas.slice(1);
    this.state = {
      photos: photos,
      picture: photos[0]
    };

    this.changePicture = this.changePicture.bind(this);
  }

  changePicture = event => {
    const index = event.currentTarget.getAttribute("data-index");
    this.setState({
      picture: this.state.photos[index]
    });
  };

  render() {
    return (
      <StyledPhotos>
        <div className="main-picture">
          <img
            alt={this.state.picture}
            src={`${process.env.REACT_APP_CDN_DOMAIN_NAME}/${this.state.picture}?w=986&h=986`}
          />
        </div>

        {this.state.photos && this.state.photos.length > 1 && (
          <ul className="miniatures">
            {this.state.photos.map((photo, index) => (
              <li key={index} onClick={this.changePicture} data-index={index}>
                {/*<div className='aspect-ratio--square'>*/}
                <div>
                  <img
                    alt={photo}
                    src={`${process.env.REACT_APP_CDN_DOMAIN_NAME}/${photo}?w=94&h=94`}
                  />
                </div>
              </li>
            ))}
          </ul>
        )}
      </StyledPhotos>
    );
  }
}

export default Photos;
