import React, { Component } from "react";
import styled from "styled-components";

const Div = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
`;

const Button = styled.button`
  appearance: none !important;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  position: absolute;
  cursor: pointer;
  z-index: 1000;

  .icon {
    display: none;
  }
`;

const Prev = styled(Button)`
  top: calc((100vh - 20px) / 2);
  left: 13px;

  .text {
    display: none;
  }

  &:before {
    color: #000;
    content: "\\003c";
    display: block;
    font-size: 36px;
    font-weight: 600;
    line-height: 36px;
    padding: 0;
    margin: 0;
  }
`;

const Next = styled(Button)`
  top: calc((100vh - 20px) / 2);
  right: 13px;

  .text {
    display: none;
  }

  &:before {
    color: #000;
    content: "\\003e";
    display: block;
    font-size: 36px;
    font-weight: 600;
    line-height: 36px;
    padding: 0;
    margin: 0;
  }
`;

class Modal extends Component {
  styleClose = null;
  stylePrev = null;
  styleNext = null;
  styleContent = null;

  constructor(props, options) {
    super(props);

    if (options) {
      this.styleClose = options.styleClose;
      this.stylePrev = options.stylePrev;
      this.styleNext = options.styleNext;
      this.styleContent = options.styleContent;
    }
  }

  render() {
    const {
      children,
      handleCloseModal,
      handlePrevious,
      handleNext
    } = this.props;

    const StyledPrev = this.stylePrev ? styled(this.stylePrev)`` : Prev;
    const StyledNext = this.styleNext ? styled(this.styleNext)`` : Next;

    const StyledClose = this.styleClose
      ? styled(this.styleClose)``
      : styled(Button)`
          top: 13px;
          left: auto;
          right: 13px;

          .text {
            display: none;
          }

          &:before {
            color: #000;
            content: "\00D7";
            display: block;
            font-size: 36px;
            font-weight: 600;
            line-height: 36px;
            padding: 0;
            margin: 0;
          }
        `;

    const StyledContent = this.styleContent
      ? styled(this.styleContent)``
      : styled.div`
          display: flex;
          flex-direction: column;
          margin-left: auto;
          margin-right: auto;
          box-sizing: border-box;
          height: 100vh;
          background: #fff;
          position: relative;
          overflow-y: scroll;
          -webkit-overflow-scrolling: touch;
        `;

    return (
      <Div>
        {handlePrevious && (
          <StyledPrev onClick={handlePrevious}>
            <span className="icon" />
            <span className="text">Précédent</span>
          </StyledPrev>
        )}
        {handleNext && (
          <StyledNext onClick={handleNext}>
            <span className="icon" />
            <span className="text">Suivant</span>
          </StyledNext>
        )}
        <StyledClose onClick={handleCloseModal}>
          <span className="icon" />
          <span className="text">Fermer</span>
        </StyledClose>
        <StyledContent className="modal-content">{children}</StyledContent>
      </Div>
    );
  }
}

export default Modal;
