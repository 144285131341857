import React from "react";
import { Content } from "../../template";

const Page404 = () => (
  <Content title="Page introuvable">
    <p>Erreur 404</p>
  </Content>
);

export default Page404;
