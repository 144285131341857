import styled from "styled-components";
import DefaultModal from "../innedit_src/templates/Modal";
import chevronRight from "../assets/images/chevron.svg";
import close from "../assets/images/close.svg";

const Button = styled.button`
  appearance: none !important;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  position: absolute;
  cursor: pointer;
  z-index: 1000;

  .text {
    display: none;
  }

  .icon {
    mask: url(${chevronRight}) no-repeat 100% 100%;
    background-color: ${props => (props.theme.mobile ? "#000" : "#fff")};
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: top;
  }
`;

const stylePrev = styled(Button)`
  top: calc((100vh - 20px) / 2);
  left: 13px;

  .icon {
    transform: rotate(180deg);
  }
`;

const styleNext = styled(Button)`
  top: calc((100vh - 20px) / 2);
  left: auto;
  right: 13px;
`;

const styleClose = styled(Button)`
  top: 13px;
  left: auto;
  right: 13px;

  .icon {
    mask: url(${close}) no-repeat 100% 100%;
  }
`;

const styleContent = styled.div`
  display: ${props => (props.theme.mobile ? "block" : "flex")};
  flex-direction: column;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${props => (props.theme.mobile ? 0 : "50px")};
  box-sizing: border-box;
  background: white;
  height: ${props => (props.theme.mobile ? "100vh" : "calc(100vh - 100px)")};
  position: relative;
  padding: 1.5rem;

  text-align: ${props => props.center && "center"};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

class Modal extends DefaultModal {
  constructor(props) {
    super(props, {
      styleClose: styleClose,
      stylePrev: stylePrev,
      styleNext: styleNext,
      styleContent: styleContent
    });
  }
}

export default Modal;
