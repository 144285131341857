import {
  PRODUIT_ADD_CART,
  PRODUIT_UPDATE_CART,
  PRODUIT_REMOVE_CART,
  PRODUIT_RESET_CART
} from "./actionTypes";

import { API_LOADING, API_ERROR, API_SUCCESS } from "../urls/actionTypes";

export function loadVariants(ref) {
  return dispatch => {
    dispatch({
      type: API_LOADING
    });

    return ref
      .collection("variants")
      .where("deleted", "==", false)
      .orderBy("price")
      .orderBy("libelle")
      .get()
      .then(querySnapshot => {
        const variants = [];
        if (querySnapshot.size > 0) {
          querySnapshot.docs.forEach(doc => {
            variants.push({
              id: doc.id,
              ...doc.data()
            });
          });
        }

        return dispatch({
          type: API_SUCCESS,
          variants: variants
        });
      })
      .catch(error => {
        return dispatch({
          type: API_ERROR,
          error: true,
          message: error.message
        });
      });
  };
}

export function addProductToCart(produit) {
  return {
    type: PRODUIT_ADD_CART,
    produit: produit
  };
}

export function updateProductToCart(qty, id) {
  return {
    type: PRODUIT_UPDATE_CART,
    produit: {
      qty: qty,
      id: id
    }
  };
}

export function removeProductToCart(id) {
  return {
    type: PRODUIT_REMOVE_CART,
    produitId: id
  };
}

export function resetCart() {
  return {
    type: PRODUIT_RESET_CART
  };
}
