export const PRODUITS_SIMILAIRES = "PRODUITS_SIMILAIRES";
export const PRODUITS_LOADING = "PRODUITS_LOADING";
export const PRODUITS_SUCCESS = "PRODUITS_SUCCESS";
export const PRODUITS_ERROR = "PRODUITS_ERROR";
export const PRODUIT_ADD_CART = "PRODUIT_ADD_CART";
export const PRODUIT_UPDATE_CART = "PRODUIT_UPDATE_CART";
export const PRODUIT_REMOVE_CART = "PRODUIT_REMOVE_CART";
export const PRODUIT_RESET_CART = "PRODUIT_RESET_CART";
export const COMMANDE_LOADING = "COMMANDE_LOADING";
export const COMMANDE_SUCCESS = "COMMANDE_SUCCESS";
export const COMMANDE_ERROR = "COMMANDE_ERROR";
export const DEMANDE_LOADING = "DEMANDE_LOADING";
export const DEMANDE_SUCCESS = "DEMANDE_SUCCESS";
export const DEMANDE_ERROR = "DEMANDE_ERROR";
