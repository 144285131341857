export const IS_MOBILE = "IS_MOBILE";
export const IS_WEBAPP = "IS_WEBAPP";

export const PAGE_LOADING = "PAGE_LOADING";
export const PAGE_SUCCESS = "PAGE_SUCCESS";
export const PAGE_ERROR = "PAGE_ERROR";
export const PAGE_RESET = "PAGE_RESET";

export const API_LOADING = "API_LOADING";
export const API_SUCCESS = "API_SUCCESS";
export const API_ERROR = "API_ERROR";

export const ALGOLIA_LOADING = "ALGOLIA_LOADING";
export const ALGOLIA_SUCCESS = "ALGOLIA_SUCCESS";
export const ALGOLIA_ERROR = "ALGOLIA_ERROR";
