import { IS_WEBAPP, IS_MOBILE, API_SUCCESS } from "./actionTypes";
import { USER_UPDATE_FIELDS } from "../user/actionTypes";
import { loadState } from "../../config/functions";
import SchemaObjet from "../../datas/Schema";

export function isMobile(value) {
  return {
    type: IS_MOBILE,
    test: value
  };
}

export function isWebapp(value) {
  return {
    type: IS_WEBAPP,
    test: value
  };
}

export function addDataToSchema(schemaId, data) {
  return dispatch => {
    dispatch({
      type: USER_UPDATE_FIELDS,
      data: data
    });

    // On récupére l'id de l'user dans le state
    // Cela correspond a l'id du localstorage de l'utilisateur en cours
    // Grace à cela, il est possible de retracer la navigation de l'utilisateur
    const {
      user: { id }
    } = loadState();

    data.user = {
      id: id
    };

    return dispatch({
      type: API_SUCCESS,
      payload: SchemaObjet.create(schemaId, data)
    });
  };
}
