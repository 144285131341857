import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "../../../../../template";
import { Link } from "@reach/router";
import styled from "styled-components";
import Classic from "./type/Classic";
import Variants from "./type/Variants";
import Price from "./Prix";
import Popup from "./Popup";
import { addProductToCart } from "../../../../plugins/catalogue/actions";
import {
  calcVolume,
  calcShippingCost
} from "../../../../plugins/catalogue/functions";

const StyledBuy = styled.div`
  background: #f6f6f6;
  padding: 1.5rem;
  margin: 0 -1.5rem;

  display: flex;
  flex-direction: ${props => (props.theme.mobile ? "column" : "row")};
  justify-content: space-around;

  div {
    display: block;
    flex: 1;

    &.acheter {
      text-align: center;
      margin-top: ${props => (props.theme.mobile ? "1.5rem" : 0)};

      .commentaire {
        display: block;
        color: #ddb884;
      }

      .qty {
        border: 1px solid #bbb7b1;
        font-weight: 400;
        margin: 0.75rem 0.375rem;
        display: inline-block;
        background: #fff;
        padding: 0.75rem 0.375rem;
        border-radius: 4px;
        outline: 0;
        vertical-align: middle;
        line-height: 1rem;
        text-align: center;
        width: 50px;
      }

      .livraison {
        display: block;
        font-size: 12px;
        font-style: italic;

        a {
          color: inherit;
        }

        span {
          display: block;
        }
      }
    }
  }

  ${props => {
    if (props.theme.mobile) {
      return {
        ".add_to_cart": {
          width: "calc(100% - 6rem - 50px)",
          marginLeft: "0.75rem"
        }
      };
    }
  }};
`;

class Buy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produit: null,
      qty: 1,
      showPopup: false
    };

    this.transformProduitToCart = this.transformProduitToCart.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleSelectVariant = this.handleSelectVariant.bind(this);
    this.handleAddToCart = this.handleAddToCart.bind(this);
    this.handleOnClosePopup = this.handleOnClosePopup.bind(this);
  }

  componentDidMount() {
    // if (this.props.produit) {
    this.transformProduitToCart(this.props.produit);
    // }
  }

  componentWillReceiveProps(nextProps) {
    if (
      // (!this.props.produit && nextProps.produit) ||
      this.props.produit &&
      nextProps.produit &&
      this.props.produit.id !== nextProps.produit.id
    ) {
      this.transformProduitToCart(nextProps.produit);
    }
  }

  transformProduitToCart(produit) {
    const shippingIncluded = Boolean(produit.deliveryIncluded);
    const retailPrice = produit.retailPrice
      ? parseFloat(produit.retailPrice)
      : null;
    const hasInventory = Boolean(produit.hasInventory);
    const qtyAvailable = parseInt(produit.qtyAvailable, 10);
    const minQty = produit.minQty ? parseInt(produit.minQty, 10) : 1;
    const maxQty = produit.maxQty ? parseInt(produit.maxQty, 10) : -1;
    const byNumberQty = produit.byNumberQty
      ? parseInt(produit.byNumberQty, 10)
      : 1;
    const aFabriquer =
      !produit.hasInventory || (produit.hasInventory && produit.negativeStock);

    const data = {
      id: produit.id,
      productType: produit.productType,
      pathname: produit.pathname,
      name: produit.shortname ? produit.shortname : produit.name,
      sku: produit.sku,
      photo:
        produit.photos && produit.photos.length > 0 ? produit.photos[0] : "",
      height: parseInt(produit.height, 10),
      width: parseInt(produit.width, 10),
      depth: parseInt(produit.depth, 10),
      diametre: parseInt(produit.diametre, 10),
      volume: calcVolume(produit),
      freeShipping: Boolean(produit.freeShipping),
      fixedShippingPrice: parseFloat(produit.fixedShippingPrice)
        ? parseFloat(produit.fixedShippingPrice)
        : null,
      estimateShippingCost: calcShippingCost(produit),
      shippingIncluded: shippingIncluded,
      optionPrice: produit.optionPrice,
      price: parseFloat(produit.price),
      retailPrice: retailPrice,
      hasInventory: hasInventory,
      isCustomMaded: Boolean(produit.isCustomMaded),
      qtyAvailable: qtyAvailable,
      negativeStock: Boolean(produit.negativeStock),
      aFabriquer: aFabriquer,
      minQty: minQty,
      maxQty: maxQty,
      byNumberQty: byNumberQty,
      // TODO mettre à jour le qty en fonction des quantités dans le panier
      qty: minQty // TODO vérifier minQty et byNumberQty si ils sont cohérents
    };

    this.setState({
      produit: data
    });
  }

  handleChangeValue(event) {
    let value = event.currentTarget.value;
    const name = event.currentTarget.name;

    this.setState(oldState => {
      const produit = Object.assign({}, oldState.produit);
      switch (name) {
        case "qty":
          // Il faut la quantité minimale
          if (value < produit.minQty) value = produit.minQty;
          // Il faut la qantité max si elle existe
          if (produit.maxQty > 0 && value > produit.maxQty)
            value = produit.maxQty;
          // Il faut le bon nombre de produit
          if (value % produit.byNumberQty > 0) {
            // Errreur il faut un multiple de byNumberQty
          }
          produit[name] = value;
          break;

        default:
      }

      return {
        produit
      };
    });
  }

  handleSelectVariant(e) {
    const id = e.currentTarget.value;

    this.setState(oldState => {
      const produit = Object.assign({}, oldState.produit);
      if (id) {
        const index = this.props.produit.variants.findIndex(
          variant => variant.id === id
        );

        const variant = this.props.produit.variants[index];
        // On récupere toutes les dimensions nécessaires
        const dimensions = {
          height: variant.height ? variant.height : this.props.produit.height,
          width: variant.width ? variant.width : this.props.produit.width,
          depth: variant.depth ? variant.depth : this.props.produit.depth,
          diametre: variant.diametre
            ? variant.diametre
            : this.props.produit.diametre
        };
        produit.variantId = id;
        produit.variantLibelle = variant.libelle;
        produit.price = parseFloat(variant.price);
        produit.height = dimensions.height;
        produit.width = dimensions.width;
        produit.depth = dimensions.depth;
        produit.diametre = dimensions.diametre;
        produit.volume = calcVolume(dimensions);
        if (produit.volume && !isNaN(produit.volume)) {
          produit.estimateShippingCost = calcShippingCost(dimensions);
        } else {
          produit.estimateShippingCost = -1;
        }
      } else {
        delete produit.variantId;
        delete produit.variantLibelle;
        produit.price = parseFloat(this.props.produit.price);
        produit.height = parseInt(this.props.produit.height, 10);
        produit.width = parseInt(this.props.produit.height, 10);
        produit.depth = parseInt(this.props.produit.height, 10);
        produit.diametre = parseInt(this.props.produit.height, 10);
        produit.volume = calcVolume(this.props.produit);
        if (produit.volume && !isNaN(produit.volume)) {
          produit.estimateShippingCost = calcShippingCost(this.props.produit);
        } else {
          produit.estimateShippingCost = -1;
        }
      }

      return {
        produit
      };
    });
  }

  handleAddToCart(event) {
    if (this.state.produit) {
      if (
        this.state.produit.productType === "variants" &&
        !this.state.produit.variantId
      ) {
        alert("Veuillez selectionner une déclinaison du produit");
      } else {
        this.props.addProductToCart(this.state.produit);
        // Affichage d'une popup pour confirmer l'ajout du panier et offrir les 2 choix suivants :
        // 1. Continuer à naviger sur le site
        // 2. Aller directement au panier
        this.setState({
          showPopup: true
        });
      }
    }
  }

  handleOnClosePopup = event => {
    event.preventDefault();
    this.setState({
      showPopup: false
    });
  };

  render() {
    const produit = this.state.produit;
    // TODO améliorer l'affichage de la page
    if (!produit) {
      return null;
    }

    // TODO changer les délais de livraison en fonction du stock
    return (
      <div>
        {this.state.showPopup && (
          <Popup produit={produit} handleOnClose={this.handleOnClosePopup} />
        )}
        <Price produit={produit} />
        <StyledBuy className="buy">
          {produit.productType === "classic" && <Classic produit={produit} />}
          {produit.productType === "variants" && (
            <Variants
              produit={produit}
              variants={this.props.produit.variants}
              handleSelectVariant={this.handleSelectVariant}
            />
          )}

          <div className="acheter">
            {produit.productType === "classic" && (
              <span className="commentaire">
                {produit.hasInventory
                  ? produit.qtyAvailable >= this.state.produit.qty
                    ? "En stock"
                    : produit.negativeStock
                    ? "Sur commande"
                    : "Non disponible"
                  : "Sur commande"}
              </span>
            )}
            {produit.productType === "variants" && (
              <span className="commentaire">Sur commande</span>
            )}
            <input
              name="qty"
              value={this.state.produit.qty}
              onChange={this.handleChangeValue}
              type="number"
              className="qty"
            />
            <Button
              className="add_to_cart"
              primary="true"
              onClick={this.handleAddToCart}
            >
              Ajouter au panier
            </Button>
            {/*<Button primary to={`/checkout/?product_id=${this.props.productId}`}>Acheter maintenant</Button>*/}
            {produit.shippingIncluded ? (
              <span className="livraison">
                La livraison est incluse dans le tarif et sera réalisée sous 8 à
                10 semaines (<Link to="/livraison">En savoir plus</Link>
                ).
              </span>
            ) : (
              <div className="livraison">
                {produit.estimateShippingCost > 0 ? (
                  <span className="cout">
                    Le coût de la livraison est estimée à{" "}
                    {produit.estimateShippingCost}€ (
                    <Link to="/livraison">En savoir plus</Link>.
                  </span>
                ) : (
                  <span>
                    Il n'est pas possible de calculer le coût de la livraison.
                  </span>
                )}
                <span className="delai">
                  Le délai de livraison est de 8 à 10 semaines
                </span>
              </div>
            )}
          </div>
        </StyledBuy>
      </div>
    );
  }
}

Buy.propTypes = {
  produit: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    addProductToCart: (qty, produit) => dispatch(addProductToCart(qty, produit))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Buy);
