import React from "react";
import styled from "styled-components";
import DefaultHeader from "../../innedit_src/templates/Header";
import { Hamburger } from "../../template";
import Logo from "./Logo";
import loupe from "../../assets/images/loupe.svg";

const styleHeader = styled.header`
  position: relative;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
`;

const styleMenu = styled.nav`
  position: absolute;

  display: flex;
  flex-direction: row;
  //justify-content: space-between;
  justify-content: flex-end;
  flex: 1;
  //position: ${(props) => (props.theme.mobile ? "absolute" : "relative")};
  top: ${(props) => (props.theme.mobile ? "48px" : "38px")};
  right: ${(props) => (props.theme.mobile ? "auto" : 0)};

  > ul {
    margin: 0;
    margin-right: 10px;
    padding: 0;

    li {
      display: inline-block;
      margin-left: 0.75rem;

      a {
        display: inline-block;
        color: #000;
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        //margin-top: 10px;
        //margin-bottom: 10px;

        padding: 10px;
        text-decoration: none;
        text-transform: uppercase;

        &.active {
          // background: #e7e7e7;
          font-weight: bold;
        }

        &.e-shop {
          color: #e2c06c;
        }

        &.recherche {
          padding: 0;
          .icon {
            mask: url(${loupe}) no-repeat 100% 100%;
            background-color: #000;
            display: block;
            height: 22px;
            width: 22px;
            margin: 9px;
          }

          .label {
            display: none;
          }
        }
      }

      &.active a {
        font-weight: bold;
        border-bottom: 2px solid #000;
        padding-bottom: 2px;
        //color: #97b8ac;
      }
    }
  }
`;

class Header extends DefaultHeader {
  constructor(props) {
    super(props, {
      menuItems: {
        main: [
          {
            path: "/stock",
            libelle: "Produits",
            translationKey: "products",
          },
          {
            path: "/contact",
            libelle: "Contact",
          },
        ],
      },
      styleHeader: styleHeader,
      styleMenu: styleMenu,
    });
  }

  content() {
    return (
      <React.Fragment>
        <Hamburger
          handleToggleMenu={this.handleToggleMenu}
          openMenu={this.state.openMenu}
        />
        <Logo handleRemoveMenu={this.handleRemoveMenu} />
      </React.Fragment>
    );
  }
}

export default Header;
