import React, { Component } from "react";
import Div from "./style";

class Variants extends Component {
  render() {
    if (!this.props.variants) {
      return null;
    }

    return (
      <Div>
        <h3>Déclinaisons disponibles</h3>
        <p>Ce produit est disponible en plusieurs déclinaisons :</p>
        <select onChange={this.props.handleSelectVariant}>
          <option value="">
            Veuillez sélectionner une déclinaison du produit
          </option>
          {this.props.variants.map(variant => (
            <option key={variant.id} value={variant.id}>
              {variant.libelle}
            </option>
          ))}
        </select>
      </Div>
    );
  }
}

export default Variants;
