export function calcRetailPriceForProduct(produit) {
  if (!produit.retailPrice) return false;

  return produit.shippingIncluded && !produit.freeShipping
    ? parseFloat(produit.retailPrice) + parseFloat(produit.estimateShippingCost)
    : parseFloat(produit.retailPrice);
}

export function calcPriceForProduct(produit) {
  let price = parseFloat(produit.price);
  let shippingCost = parseFloat(produit.estimateShippingCost);

  return produit.shippingIncluded && !produit.freeShipping
    ? price + shippingCost
    : price;
}

export function calcTotalPriceForProduct(produit, options) {
  let shippingCost =
    options.addShipping && !produit.shippingIncluded
      ? produit.freeShipping
        ? 0
        : produit.estimateShippingCost
      : 0;

  return (
    parseInt(produit.qty, 10) * (calcPriceForProduct(produit) + shippingCost)
  );
}

export function calcCartSubTotal(panier) {
  if (!panier || panier.length === 0) return 0;

  return panier.reduce((cumul, produit) => {
    return cumul + parseInt(produit.qty, 10) * calcPriceForProduct(produit);
  }, 0);
}

/**
 * Calcul des frais de port avec la méthode de calcul "Au volume"
 * Seul les volumes des produits avec la livraison non inclus sont pris en compte
 *
 * @param panier
 * @returns {*}
 */
export function calcCartShippingCost(panier) {
  if (!panier || panier.length === 0) return 0;

  if (
    process.env.REACT_APP_PRIX_LIVRAISON_OFFERT >= 0 &&
    calcCartSubTotal(panier) >= process.env.REACT_APP_PRIX_LIVRAISON_OFFERT
  ) {
    return 0;
  }

  let freeShipping = 0;
  // TODO faire le calcul global : volume et poids
  // Math.max(1, 1) * process.env.REACT_APP_PRIX_TRANSPORT_M3;

  const shippingCost = Math.round(
    panier.reduce((cumul, produit) => {
      if (produit.freeShipping) {
        freeShipping++;
        return cumul;
      }

      if (produit.fixedShippingPrice) {
        return cumul + produit.fixedShippingPrice;
      }

      console.info("panier calcCartShippingCost", produit);
      let volume = produit.variantVolume
        ? parseFloat(produit.variantVolume)
        : parseFloat(produit.volume);
      if (isNaN(volume)) {
        console.error(`Le volume du produit ${produit.id} n'est pas défini`);
        volume = 0;
      }

      return (
        cumul +
        Math.ceil(
          parseInt(produit.qty, 10) * (produit.freeShipping ? 0 : volume) * 10
        ) /
          10
      );
    }, 0)
  );

  if (freeShipping === panier.length) {
    // On est dans le cas de figure ou tous les produits sont en livraison gratuite
    return 0;
  }

  return shippingCost;
}

export function calcCartTotal(panier) {
  if (!panier || panier.length === 0) return 0;

  return calcCartSubTotal(panier) + calcCartShippingCost(panier);
}

/**
 * Cette fonction permet de calculer le volume d'un produit
 *
 * @param produit
 */
export function calcVolume(produit) {
  let volume =
    (parseInt(produit.width, 10) *
      parseInt(produit.depth, 10) *
      parseInt(produit.height, 10)) /
    1000000;
  if (produit.diametre) {
    volume =
      (Math.max(parseInt(produit.depth, 10), parseInt(produit.width, 10)) *
        Math.PI *
        parseInt(produit.height, 10)) /
      10000;
  }

  return volume;
}

export function calcShippingCost(produit) {
  if (produit.freeShipping) return 0;

  const volume = calcVolume(produit);
  let estimateShippingCost = parseFloat(
    process.env.REACT_APP_PRIX_TRANSPORT_M3
  );

  if (!isNaN(volume)) {
    estimateShippingCost = produit.fixedShippingPrice
      ? parseFloat(produit.fixedShippingPrice)
      : Math.round(
          Math.max(1, volume) *
            parseFloat(process.env.REACT_APP_PRIX_TRANSPORT_M3)
        );
  }

  return estimateShippingCost;
}
