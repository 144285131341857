import styled from "styled-components";
import ripple from "../assets/images/loading.svg";


const Loader = styled.div`
  position: fixed;
  background: url(${ripple}) no-repeat center center rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 20000;
`;

export default Loader;
