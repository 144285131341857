import styled from "styled-components";

export default styled.div`
  box-sizing: border-box;

  width: ${props => (props.theme.mobile ? "100%" : "50%")};
  padding: ${props => (props.theme.mobile ? "0" : "0 0.75rem 0 0.75rem")};
  margin: 0 0 2rem 0;

  &.hidden {
    display: none;
  }

  &.group {
    margin-bottom: 0;

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea,
    select {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.group + &.group {
    &:not(.first) {
      input[type="text"],
      input[type="email"],
      input[type="tel"],
      textarea,
      select {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: none;
      }
    }
  }

  &.group.last {
    margin-bottom: 2rem;

    &.border-radius {
      input[type="text"],
      input[type="email"],
      input[type="tel"],
      textarea,
      select {
        border-bottom-left-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
      }
    }
  }

  &.full-width {
    width: 100%;
  }

  label:not(.radio) {
    display: block;
    color: #666;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
    white-space: nowrap;
    text-align: left;

    small {
      float: right;
      color: #757575;
      font-size: 0.71429rem;
      vertical-align: bottom;
      text-transform: uppercase;
    }
  }

  label.radio {
    margin-right: 0.75rem;
    margin-bottom: ${props => (props.theme.mobile ? "0.75rem" : "0")};
    input {
      margin-right: 0.375rem;
      &.top {
        display: block;
        margin: 0 auto 0.75rem auto;
      }
    }
  }

  &.border label.radio {
    border: 1px solid #bbb7b1;
    background-color: #f6f6f6;
    padding: 0.75rem;
    display: inline-block;

    &.border-radius {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &.select {
    position: relative;
    &:after {
      border: 1px solid #ccc;
      border-right: 0;
      border-top: 0;
      content: " ";
      display: block;
      height: 0.5em;
      pointer-events: none;
      position: absolute;
      transform: rotate(-45deg);
      width: 0.5em;
      right: 28px;
      top: 43px;
      z-index: 4;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea,
  select {
    outline: none;
    box-sizing: border-box;
    width: 100%;
    //background-color: #fff;
    background-color: #f6f6f6;
    border: 1px solid #bbb7b1;
    color: #666;
    height: 3rem;
    padding: 0.75rem 1rem;
    transition: border-color 0.1s ease-out;
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    position: relative;
    cursor: pointer;
    display: inline-block;
    font-weight: 300;
  }

  &.border-radius {
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea,
    select {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;