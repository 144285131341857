import { combineReducers } from "redux";
import pages from "../../urls/reducer";
import catalogue from "../../catalogue/reducer";
import user from "../../user/reducer";

const rootReducer = combineReducers({
  pages,
  catalogue,
  user
});

export default rootReducer;
