import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
import monogramme from "../../assets/images/monogramme.svg";
import logo from "../../assets/images/logo.svg";
import logoRond from "../../assets/images/logo.png";

const Div = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: ${props => props.theme.mobile ? "48px": "120px"};
  justify-content: center;
  
  .logo-rond {
    width: 120px;
    display: ${props => props.theme.mobile ? "none" : "inline-block"};
    position: absolute;
    left: 30px;
  }

  a {
    outline: none;
    display: inline-block;
    height: ${props => (props.theme.mobile ? "auto" : "80px")};

    .logo {
      display: inline-block;
      margin-top: 10px;
      margin-bottom: 10px;
      //background-image:${props =>
        props.theme.mobile ? `url(${monogramme}` : `url(${logo}`} );
      background-image: url(${logo});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      // height: ${props => (props.theme.mobile ? "20px" : "40px")};;
      height: ${props => (props.theme.mobile ? "35px" : "60px")};;
      width: ${props => (props.theme.mobile ? "100%" : "360px")};
      color: transparent;
    }
  }
`;

const Logo = ({ handleRemoveMenu }) => (
  <Div>
    <img
      className="logo-rond"
      src={logoRond}
      alt="Matthieu Leclercq Antiquités"
    />
    <Link to="/" onClick={handleRemoveMenu}>
      <span className="logo">Matthieu Leclercq Antiquités</span>
    </Link>
  </Div>
);

export default Logo;
