import React from "react";

const getInfosFromRequests = requests => {
  let volumeTotal = 0;
  let nbProduits = 0;
  const produits = [];
  const total = requests.reduce((accumulator, request) => {
    if (request.produit && request.isBuy) {
      nbProduits++;
      let volume = 0;
      if (request.produit.diametre) {
        // Le produit est rond
        volume =
          Math.ceil(
            (request.produit.height *
              request.produit.diametre *
              request.produit.diametre) /
              100000
          ) / 10;
      } else {
        volume =
          Math.ceil(
            (request.produit.height *
              request.produit.width *
              request.produit.depth) /
              100000
          ) / 10;
      }

      volumeTotal += volume;

      let dimensions = "";
      if (request.produit.diametre) {
        dimensions = `Dimensions : ${request.produit.diametre} cm de diamètre sur ${request.produit.height} cm (${volume} m3)`;
      } else {
        dimensions = `Dimensions : ${request.produit.depth} x ${request.produit.width} sur ${request.produit.height} cm (${volume} m3)`;
      }
      produits.push({
        id: String(request.produit_id),
        sku: String(request.produit.sku),
        dimensions: dimensions,
        volume: volume,
        prix: request.produit_prixDeVente,
        unite: request.produit_unite,
        name: request.produit_name,
        photo: request.produit_photo,
        pathname: request.produit.pathname
      });

      return accumulator + parseFloat(request.produit_prixDeVente);
    }

    return accumulator;
  }, 0);

  volumeTotal = Math.round(volumeTotal * 10) / 10;

  return {
    volume: volumeTotal,
    total,
    nbProduits,
    produits
  };
};

const initProduit = (produitData, deliveryCostVolume) => {
  let produit = {
    name: produitData.name ? produitData.name : "",
    min: produitData.minQty ? produitData.minQty : 1,
    diametre: produitData.diametre ? Number(produitData.diametre) : 0,
    height: produitData.height ? Number(produitData.height) : 0,
    width: produitData.width ? Number(produitData.width) : 0,
    depth: produitData.depth ? Number(produitData.depth) : 0,
    freeShipping: Boolean(produitData.freeShipping),
    deliveryIncluded: Boolean(produitData.deliveryIncluded),
    pathname: produitData.pathname,
    optionPrice: produitData.optionPrice,
    sku: produitData.sku
  };

  produit.isSold = false;
  if (produitData.hasInventory) {
    // Le produit à un inventaire
    if (parseInt(produitData.qtyAvailable, 10) > 0) {
      // Il y a du stock mais il faut le confirmer
      // TODO faire la vérification pour être sur que le produit est en stock
      produit.stock = (
        <span className="inventaire disponible">stock à confirmer</span>
      );
    } else {
      // En rupture de stock
      produit.stock = <span className="inventaire rupture-stock">Vendu</span>;
      produit.isSold = true;
    }
  } else {
    // Pas d'inventaire sur le produit
    produit.stock = (
      <span className="inventaire fab-or-order">
        A fabriquer ou à commander
      </span>
    );
  }

  if (produitData.prixDeVente && produitData.prixBarre) {
    // Caculer la remise
    const remiseEnEuro = produitData.prixBarre - produitData.prixDeVente;
    switch (produitData.showDiscount) {
      case "value":
        produit.remise = `-${remiseEnEuro}€`;
        break;

      case "percentage":
      default:
        const pourcentage = Math.round(
          (remiseEnEuro * 100) / produitData.prixBarre
        );
        produit.remise = `-${pourcentage}%`;
    }
  }

  switch (produitData.prixUnite) {
    case "paire":
      produit.unite = "la paire";
      break;

    case "lot":
      produit.unite = "le lot";
      break;

    case "piece":
    default:
      produit.unite = "pièce";
  }

  if (produitData.fixedShippingPrice) {
    produit.fixedShippingPrice = Number(produitData.fixedShippingPrice);
  }

  if (produit.diametre) {
    // Le produit est rond
    produit.volume =
      Math.ceil(
        (produit.height * produit.diametre * produit.diametre) / 100000
      ) / 10;
  } else {
    produit.volume =
      Math.ceil((produit.height * produit.width * produit.depth) / 100000) / 10;
  }

  if (produit.volume > 0 && deliveryCostVolume) {
    produit.livraisonAuVolume =
      produit.min * Math.round(produit.volume * deliveryCostVolume);
  } else {
    produit.livraisonAuVolume = -1;
  }

  produit.isBuy =
    (produitData.hasInventory && parseInt(produitData.qtyAvailable, 10) > 0) ||
    !produitData.hasInventory;

  return produit;
};

export { getInfosFromRequests, initProduit };
