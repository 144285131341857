import styled from "styled-components";


export default styled.div`
  text-align: center;

  h3 {
    margin: 0 0 10px 0;
  }
  p {
    width: 90%;
    font-size: 13px;
    margin: 0 auto 5px auto;
  }

  .autres {
    font-style: italic;
    font-size: 11px;
    margin-top: 15px;
  }
`;
