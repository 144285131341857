import React, { Component } from "react";
import PropTypes from "prop-types";
import defaultPicture from "../../../assets/images/picture.svg";
import { Link } from "@reach/router";
import Prix from "./Prix";

class Card extends Component {
  constructor(props) {
    super(props);

    this.imgRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.produit) {
      this.loadImage();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (!this.props.produit && nextProps.produit) ||
      (this.props.produit &&
        nextProps.produit &&
        this.props.produit.id !== nextProps.produit.id)
    ) {
      this.loadImage();
    }
  }

  loadImage() {
    const _this = this;
    const src = this.imgRef.current.src;
    this.imgRef.current.src = defaultPicture;
    const image = new Image();
    image.onload = function(e) {
      if (_this.imgRef && _this.imgRef.current) {
        _this.imgRef.current.src = src;
      }
    };
    image.src = src;
  }

  render() {
    const { produit, imageCardSize, index, nameAttribute } = this.props;
    let photo = defaultPicture;

    const options = imageCardSize ? imageCardSize : "h=640&w=640";

    if (produit.photos && produit.photos.length > 0) {
      photo = `${process.env.REACT_APP_CDN_DOMAIN_NAME}/${
        produit.photos[0]
      }?${options}`;
    } else {
      if (produit.src) {
        photo = produit.src;
      } else {
        // Il n'y a pas de photos
      }
    }

    let libelle =
      nameAttribute && produit[nameAttribute]
        ? produit[nameAttribute]
        : produit.name;

    if (
      window.navigator.language.toLocaleLowerCase().substring(0, 2) !== "fr" &&
      produit.traductions &&
      produit.traductions.en
    ) {
      const lang = produit.traductions.en;
      libelle =
        nameAttribute && lang[nameAttribute]
          ? lang[nameAttribute]
          : lang.name
          ? lang.name
          : libelle;
    }

    return (
      <li>
        <Link
          data-id={produit.id}
          data-index={index}
          to={`/${produit.pathname}`}
          onClick={this.props.handleOnClick}
        >
          <img ref={this.imgRef} src={photo} alt={produit.name} />
        </Link>
        <h4>
          {this.props.showPrice && <Prix produit={produit} />}
          <Link
            data-id={produit.id}
            data-index={index}
            to={`/${produit.pathname}`}
            onClick={this.props.handleOnClick}
          >
            {libelle}
          </Link>
        </h4>
      </li>
    );
  }
}

Card.propTypes = {
  produit: PropTypes.object.isRequired
};

export default Card;
