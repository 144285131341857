import { getFirestore } from "../config/functions";

class Demande {
  static findById(id) {
    return getFirestore()
      .collection("demandes")
      .doc(id)
      .get()
      .then(documentSnapshot => {
        if (documentSnapshot.exists && !documentSnapshot.get("deleted")) {
          return documentSnapshot;
        }
        throw new Error("La demande n'existe pas ou elle a été supprimée");
      });
  }

  static watch(id, next) {
    return getFirestore()
      .collection("demandes")
      .doc(id)
      .onSnapshot(next);
  }
}

export default Demande;
