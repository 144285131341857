import React from "react";
import styled from "styled-components";
import Copyright from "./Copyright";
import { Link } from "@reach/router";

const FooterEL = styled.footer`
  background: #cdc6bc;
  margin-top: 100px;
  display: flex;
  flex-flow: column;
  font-size: 12px;
  padding: 1.5rem 1.5rem 0.75rem 1.5rem;
  width: ${(props) => (props.theme.mobile ? "100%" : "auto")};
  box-sizing: border-box;
  color: #423f3e;
  font-family: Arial, sans-serif;

  .footer {
    display: flex;
    max-width: 1080px;
    flex-flow: ${(props) => (props.theme.mobile ? "column" : "row")};

    > div {
      margin-left: ${(props) => (props.theme.mobile ? 0 : "60px")};
      flex: 1;

      .double,
      .triple {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        ul {
          flex: 1;
          margin-right: 30px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &:last-child {
        flex: none;
      }
    }
    .name {
      margin: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      a {
        text-transform: capitalize;
        white-space: nowrap;
      }
    }
  }

  h4 {
    font-size: 16px;
    margin: 0 0 1.5rem 0;
    white-space: nowrap;
  }

  p {
    margin: 0 0 0.75rem 0;
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
`;

const Footer = () => (
  <FooterEL>
    <div className="footer">
      <div>
        <h4>Entreprise</h4>
        <ul>
          <li>
            <Link to="/histoire">Histoire</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
      <div>
        <h4>Catégories</h4>
        <div className="triple">
          <ul>
            <li>
              <Link to="/stock/sieges">Sièges</Link>
            </li>
            <li>
              <Link to="/stock/tables">Tables</Link>
            </li>
            <li>
              <Link to="/stock/meubles">Meubles</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/stock/miroirs">Miroirs</Link>
            </li>
            <li>
              <Link to="/stock/elements-architecturaux">
                éléments Architecturaux
              </Link>
            </li>
            <li>
              <Link to="/stock/consoles">Consoles</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/stock/tableaux">Tableaux</Link>
            </li>
            <li>
              <Link to="/stock/objets">Objets</Link>
            </li>
            <li>
              <Link to="/stock/luminaires">Luminaires</Link>
            </li>
          </ul>
        </div>
      </div>

      <div>
        <h4>Collections</h4>
        <ul>
          <li>
            <Link to="/stock/jardin">Jardin</Link>
          </li>
        </ul>
      </div>
      <div>
        <h4>Liens</h4>
        <ul>
          <li>
            <Link to="/transport">Transport</Link>
          </li>
          <li>
            <Link to="/newsletters">Newsletters</Link>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/matthieu_leclercq_lm/"
            >
              Instagram
            </a>
          </li>
        </ul>
      </div>
    </div>

    <Copyright />
  </FooterEL>
);

export default Footer;
