import React from "react";
import Helmet from "react-helmet";
import Produits from "../plugins/catalogue/Produits";
import { Link } from "@reach/router";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import screens from "../styles/screens";

const CatalogueSC = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${screens.md}) {
    flex-direction: row;
  }

  .filter {
    width: 100%;

    @media (min-width: ${screens.md}) {
      width: 200px;
      margin-right: 3rem;
    }

    h1 {
      margin: 0 0 1.5rem 0;
      padding: 0;
      text-align: ${(props) => (props.theme.mobile ? "center" : "right")};
      width: ${(props) => (props.theme.mobile ? "100%" : "200px")};
      text-transform: capitalize;
    }

    .navigation {
      width: 100%;
      margin-bottom: ${(props) => (props.theme.mobile ? "1.5rem" : 0)};

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;

        @media (min-width: ${screens.md}) {
          text-align: right;
        }

        a {
          color: #222;
          text-decoration: none;
          text-transform: capitalize;

          &.active {
            font-weight: 700;
          }
        }
      }
    }
  }

  .produits {
    display: flex;
    flex-direction: column;
  }
`;

const Navigation = ({ pathname }) => {
  const { t } = useTranslation();

  return (
    <div className="navigation">
      <ul>
        <li style={{ marginBottom: "1.5rem" }}>
          <Link
            to="/stock"
            className={pathname === "/stock" ? "active" : ""}
            style={{ textTransform: "inherit" }}
          >
            {t("store.all-products")}
          </Link>
        </li>
        <li>
          <Link
            to="/stock/meubles"
            className={pathname === "/stock/meubles" ? "active" : ""}
          >
            Meubles
          </Link>
        </li>
        <li>
          <Link
            to="/stock/consoles"
            className={pathname === "/stock/consoles" ? "active" : ""}
          >
            Consoles
          </Link>
        </li>
        <li>
          <Link
            to="/stock/sieges"
            className={pathname === "/stock/sieges" ? "active" : ""}
          >
            Sièges
          </Link>
        </li>
        <li>
          <Link
            to="/stock/jardin"
            className={pathname === "/stock/jardin" ? "active" : ""}
          >
            Jardin
          </Link>
        </li>
        <li>
          <Link
            to="/stock/tables"
            className={pathname === "/stock/tables" ? "active" : ""}
          >
            Tables
          </Link>
        </li>

        <li>
          <Link
            to="/stock/luminaires"
            className={pathname === "/stock/luminaires" ? "active" : ""}
          >
            Luminaires
          </Link>
        </li>
        <li>
          <Link
            to="/stock/miroirs"
            className={pathname === "/stock/miroirs" ? "active" : ""}
          >
            Miroirs
          </Link>
        </li>
        <li>
          <Link
            to="/stock/objets"
            className={pathname === "/stock/objets" ? "active" : ""}
          >
            Objets
          </Link>
        </li>
        <li>
          <Link
            to="/stock/tableaux"
            className={pathname === "/stock/tableaux" ? "active" : ""}
          >
            Tableaux
          </Link>
        </li>
        <li>
          <Link
            to="/stock/elements-architecturaux"
            className={
              pathname === "/stock/elements-architecturaux" ? "active" : ""
            }
          >
            éléments archicturaux
          </Link>
        </li>
        <li className="vendus" style={{ marginTop: "1.5rem" }}>
          <Link to="/vendus" className={pathname === "/vendus" ? "active" : ""}>
            {t("store.sold")}
          </Link>
        </li>
      </ul>
    </div>
  );
};

const Catalogue = ({
  title,
  translationKey,
  location: { pathname },
  ...others
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={title} />
      <CatalogueSC>
        <div className="filter">
          <h1>{translationKey ? t(translationKey) : title}</h1>
          <Navigation pathname={pathname} />
        </div>
        <div className="produits">
          <Produits hidePaginationTop={false} {...others} />
        </div>
      </CatalogueSC>
    </React.Fragment>
  );
};

export default Catalogue;
