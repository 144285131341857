import React from "react";
import styled from "styled-components";
import ambiance5 from "../assets/photos/ambiance-5.jpg";
import ambiance6 from "../assets/photos/ambiance-6.jpg";
import ambiance7 from "../assets/photos/ambiance-7.jpg";
import ambiance8 from "../assets/photos/ambiance-8.jpg";

const Div = styled.div`
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: ${props => (props.theme.mobile ? "100%" : "1040px")};
  position: relative;
  .image-top {
    ${props => {
      if (!props.theme.mobile)
        return {
          position: "absolute",
          top: "0px",
          right: 0,
          height: "600px"
        };
    }}
  }

  .main {
    font-size: 16px;
    position: relative;
    padding-bottom: ${props => props.theme.mobile ? 0 : "80px"};

    .text {
      width: 350px;
    }

    .i {
      position: absolute;
      left: 0;
      font-size: 60px;
      line-height: 60px;
    }

    .image-middle {
      ${props => {
        if (!props.theme.mobile)
          return {
            marginTop: "20px",
            marginLeft: "40px"
          };
      }}
    }

    .image-bottom {
      ${props => {
        if (!props.theme.mobile)
          return {
            position: "absolute",
            bottom: 0,
            right: "40px",
            height: "400px"
          };
      }}
    }

    .bigname {
      //position: absolute;
      text-transform: uppercase;
      ${props => {
        if (!props.theme.mobile)
          return {
            position: "absolute",
            top: "620px",
            left: "440px",
            fontSize: "50px",
            lineHeight: "60px"
          };

        return {
          left: "0",
          fontSize: "30px"
        };
      }}

      span {
        display: block;
      }
    }
  }

  .carrousel {
    position: relative;
    display: flex;
    flex-direction: ${props => (props.theme.mobile ? "column" : "row")};
    max-width: 100%;
    //overflow: hidden;
    justify-content: center;
    img {
      height: 400px;
    }

    img + img {
      ${props => {
        if (!props.theme.mobile) {
          return {
            marginLeft: "40px"
          };
        }

        return {
          marginTop: "0.75rem"
        };
      }}
  }
`;

const Index = () => {
  return (
    <Div>
      <img className="image-top" src={ambiance5} alt="Ambiance 5" />
      <div className="main">
        <div className="text">
          <p style={{ marginLeft: "30px" }}>
            <span className="i">I</span>nstallé à Bondues à coté de Lille dans
            le Nord de la France depuis 1998.
          </p>
          <p>
            Je recherche pour vous ce que j'aime, ce qui me crée une émotion, ce
            qui me raconte une histoire. Les meubles et objets XVIIIe et XIXe de
            charme, le mobilier de jardin ancien, d'élégants éléments
            d'architecture, les beaux meubles de commerce anciens, enfin les
            patines du temps irrésistibles, inimitables, indémodables.
          </p>
          <img className="image-middle" src={ambiance7} alt="Ambiance 5" />
          {/*<img*/}
            {/*className="image-bottom"*/}
            {/*src={ambiance6}*/}
            {/*alt="Ambiance et style"*/}
          {/*/>*/}
          <span className="bigname">
            <span className="pos1">irrésistibles</span>
            <span className="pos2">inimitables</span>
            <span className="pos3">indémodables</span>
          </span>
        </div>
      </div>
      <div className="carrousel">
        <img className="ambiance" src={ambiance8} alt="Ambiance et style" />
        <img className="ambiance" src={ambiance6} alt="Ambiance 3" />
      </div>
    </Div>
  );
};
export default Index;
