import React from "react";
import styled from "styled-components";
import Prix from "../../Prix";

const StylePrice = styled.div`
  margin: 1.5rem 0;
  text-align: center;
  font-family: "Georgia", serif;

  .retail-price {
    text-decoration: line-through;

    &:after {
      content: " €";
      //margin-left: 0.1875rem;
      font-size: 85%;
    }
  }

  .price,
  .start_price {
    display: block;
    text-align: center;
    color: #ddb884;
    font-size: 26px;
    line-height: 30px;
  }
  .start_price {
    font-size: 19px;
  }

  .info {
    display: inline-block;
    text-align: center;
    color: #ddb884;
    font-size: 22px;
    line-height: 30px;

    //font-style: italic;
  }
`;

const PrixCmp = ({ produit, info }) => {
  return (
    <StylePrice>
      <Prix produit={produit} info={info} />
    </StylePrice>
  );
};

export default PrixCmp;
