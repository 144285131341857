import React from "react";
import styled from "styled-components";
import Photos from "./Photos";
const md = require("markdown-it")();

const Div = styled.div`
  display: flex;
  margin-top: ${(props) => (props.theme.mobile ? "1.5rem" : "3rem")};

  ${(props) => {
    if (props.theme.mobile) {
      return {
        flexDirection: "column",
      };
    }
  }};

  > div {
    flex: 4;
  }

  dl {
    display: block;

    dt,
    dd {
      display: inline;
    }

    dt {
      margin-right: 1.5rem;
      font-weight: bold;
      white-space: nowrap;
    }

    dd {
      margin: 0;
      &:after {
        content: "";
        display: table;
      }
    }
  }

  .more-photos {
    margin-top: 1.5rem;
    margin-right: ${(props) => (props.theme.mobile ? 0 : "1.5rem")};
    flex: 5;
  }

  h3 {
    clear: both;
  }
`;
const Informations = ({ data }) => {
  let renseignements = [];

  if (data.features && data.features.length > 0) {
    data.features.forEach((feature, index) => {
      if (feature.libelle && feature.valeur) {
        renseignements.push(<dt key={`${index}_1`}>{feature.libelle}</dt>);
        renseignements.push(<dd key={`${index}_2`}>{feature.valeur}</dd>);
      }
    });
  }

  return (
    <Div>
      {data.photos && data.photos.length > 1 && <Photos datas={data.photos} />}

      <div className="description">
        {data.shortname && <h3>{data.name}</h3>}
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: md.render(data.description) }}
        />
        <h3>Renseignements</h3>
        {renseignements.length > 0 && <dl>{renseignements}</dl>}
        <h3>Garantie de conformité</h3>
        <p>
          Tous les produits sont garantie pendant 2 ans en cas de non
          conformité.
        </p>
      </div>
    </Div>
  );
};

export default Informations;
