import { createHistory } from "@reach/router";
import throttle from "lodash/throttle";
import { mobilecheck, loadState, saveState } from "./functions";
import createStore from "../plugins/redux/configureStore";
import { isMobile, isWebapp } from "../plugins/urls/actions";
import { v4 as uuidv4 } from "uuid";
// import StatistiqueObjet from "../datas/Statistique";
// import moment from "moment";

// const saveDocumentToStatistiques = pathname => {
//   const state = loadState();
//   const stat = {
//     createdAt: moment().toISOString(),
//     boutique: process.env.REACT_APP_ID_BOUTIQUE,
//     env: process.env.NODE_ENV,
//     user: state.user,
//     catalogue: state.catalogue,
//     devicePixelRatio: window.devicePixelRatio,
//     innerHeight: window.innerHeight,
//     innerWidth: window.innerWidth,
//     navigator: {
//       language: window.navigator.language,
//       userAgent: window.navigator.userAgent,
//       mobile: mobilecheck(),
//       webApp: !!window.navigator.standalone
//     },
//     location: {
//       href: window.location.href,
//       pathname: pathname
//     },
//     screen: {
//       height: window.screen.height,
//       width: window.screen.width,
//       orientation: window.screen.orientation
//         ? window.screen.orientation.type
//         : ""
//     },
//     document: {
//       referrer: document.referrer,
//       title: document.title
//     }
//   };
//
//   if (process.env.NODE_ENV === "development") {
//     console.info("page", pathname);
//   }
//
//   StatistiqueObjet.create(stat);
//
//   setTimeout(() => {
//     const location = { ...window.location };
//     const title = document.title;
//
//     window.gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
//       page_title: title,
//       page_path: location.pathname
//     });
//   }, 1000);
// };

const createContext = () => {
  let persistedState = loadState();
  if (persistedState) {
    if (!persistedState.user) {
      persistedState.user = {
        id: uuidv4(),
      };
    } else {
      if (!persistedState.user.id) {
        persistedState.user.id = uuidv4();
      }
    }
  } else {
    persistedState = {
      user: {
        id: uuidv4(),
      },
    };
  }

  const store = createStore(persistedState);
  store.subscribe(
    throttle(() => {
      const cloneUser = { ...store.getState().user };
      delete cloneUser.isLoading;
      delete cloneUser.isLogged;
      delete cloneUser.user;

      saveState({
        catalogue: store.getState().catalogue,
        user: cloneUser,
      });
    }, 1000)
  );

  const isMobileScreen = mobilecheck();
  const viewport = document.querySelector("meta[name=viewport]");
  viewport.setAttribute(
    "content",
    "width=device-width, height=device-height, initial-scale=1, , initial-scale=1"
  );
  if (mobilecheck()) {
    // viewport.setAttribute('content', 'width=device-width, height=device-height, initial-scale=1, , initial-scale=1, user-scalable=no');
    // , maximum-scale=1.0, user-scalable=0
    // setTimeout(() => { window.scrollTo(0, 1); }, 1000);
    document.documentElement.classList.remove("desktop");
    document.documentElement.classList.add("mobile");
  } else {
    // viewport.setAttribute("content", "width=980");
    // viewport.setAttribute('content', 'width=980, user-scalable=no');
    document.documentElement.classList.add("desktop");
    document.documentElement.classList.remove("mobile");
  }

  const isWebappScreen = !!window.navigator.standalone;
  if (isWebappScreen) {
    document.documentElement.classList.add("webapp");
  } else {
    document.documentElement.classList.remove("webapp");
  }

  store.dispatch(isMobile(isMobileScreen));
  store.dispatch(isWebapp(isWebappScreen));

  // listen to the browser history
  let history = createHistory(window);

  // saveDocumentToStatistiques(window.location.pathname + window.location.search);
  //
  // history.listen(({ location: { pathname } }) => {
  //   saveDocumentToStatistiques(pathname);
  // });

  return { store, history, isMobileScreen };
};

export default createContext;
