import React from "react";
import styled from "styled-components";
import { Content } from "../../../template";
import Card from "./Card";
import DefaultProduits from "../../../innedit_src/containers/Produits";
import chevron from "../../../assets/images/chevron.svg";

const stylePagination = styled.div`
  display: flex;
  margin: 0 0.75rem;

  ul {
    flex: 1;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0.375rem;

      .page {
        box-sizing: border-box;
        cursor: pointer;

        &.prev {
          .text {
            display: none;
          }
          .icon {
            mask: url(${chevron});
            width: 14px;
            height: 14px;
            margin: 8px 0 8px 7px;
            background: #333;
            display: block;
            transform: rotate(180deg);
          }
        }

        &.next {
          .text {
            display: none;
          }
          .icon {
            mask: url(${chevron});
            width: 14px;
            height: 14px;
            margin: 8px 0 8px 9px;
            background: #333;
            display: block;
          }
        }

        &.selected {
          color: #97b8ac;
        }
      }
    }
  }

  .infos {
    height: 30px;
    line-height: 30px;
    align-self: center;
  }
`;

const ProduitsSC = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    width: ${(props) =>
      props.nbByRow ? (1 / props.nbByRow) * 100 + "%" : "50%"};
    overflow: hidden;
    padding-bottom: 3rem;
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft : "3rem"};
    padding-right: ${(props) =>
      props.paddingRight ? props.paddingRight : "3rem"};
    box-sizing: border-box;

    img {
      width: calc(100% - 8px);
      //border: 4px solid #fff;
    }

    h4 {
      text-align: center;
      margin: 0;
      font-size: 15px;
      a {
        color: #1c1d22;
        text-decoration: none;
      }
    }

    ${(props) => {
      if (props.theme.mobile) {
        return {
          width: "100% !important",
          paddingLeft: 0,
          paddingRight: 0,
          margin: "0 !important",
        };
      }
    }};
  }

  &.realisations {
    ${(props) => {
      if (props.theme.mobile) {
        return {
          li: {
            width: "50% !important",
            paddingLeft: "0.75rem !important",
            paddingRight: "0.75rem !important",
          },
        };
      }
    }};
  }

  &.no-padding li {
    padding: 0;
  }

  &.home li {
    padding-bottom: 0;
  }
`;

class Produits extends DefaultProduits {
  stylePagination = stylePagination;

  showEmpty() {
    if (this.state.produits && this.state.produits.length === 0) {
      switch (this.props.path) {
        case "store":
          return (
            <Content>
              <img
                src="https://media.giphy.com/media/l2JhFNSweKM8fQ8aA/giphy.gif"
                width="60%"
                style={{ margin: "0 auto" }}
                alt="No"
              />
              <p style={{ textAlign: "center" }}>
                Nous n'avons plus de produits en stock pour le moment.
              </p>
            </Content>
          );

        default:
          return <p>Aucun produit</p>;
      }
    }
  }

  showList() {
    if (!this.state.produits || this.state.produits.length === 0) {
      return null;
    }

    return (
      <ProduitsSC
        nbByRow={this.props.nbByRow}
        paddingLeft={this.props.paddingLeft}
        paddingRight={this.props.paddingRight}
      >
        {this.state.produits.map((produit, index) => (
          <Card
            produit={produit}
            nameAttribute={this.props.nameAttribute}
            key={index}
            index={index}
            showPrice={this.props.showPrice}
            handleOnClick={this.handleOnClick}
            imageCardSize={this.props.imageCardSize}
          />
        ))}
      </ProduitsSC>
    );
  }
}

export default Produits;
