import React from "react";
import PropTypes from "prop-types";
import { Title } from "../../../../template";
import Photos from "./Photos";
import ShowPrice from "./ShowPrice";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import screens from "../../../../styles/screens";
const md = require("markdown-it")();

const Div = styled.div`
  display: grid;

  gap: 3rem;
  margin-top: 3rem;
  @media (min-width: ${screens.md}) {
    grid-template-columns: repeat(2, 1fr);
  }

  dl {
    display: block;

    dt,
    dd {
      display: inline;
    }

    dt {
      margin-right: 1.5rem;
      font-weight: bold;
      white-space: nowrap;
    }

    dd {
      margin: 0;
      &:after {
        content: "";
        display: table;
      }
    }
  }

  h3 {
    clear: both;
  }
`;

const PhotosEL = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DescriptionEl = styled.div`
  @media (min-width: ${screens.md}) {
    max-width: 300px;
  }
`;

const Stock = ({ document }) => {
  const data = document.data();
  const { t } = useTranslation();

  let dimensions = [];
  if (data.width) {
    dimensions.push(<dt key="0_1">{t("product.width")}</dt>);
    dimensions.push(<dd key="0_2">{data.width} cm</dd>);
  }
  if (data.depth) {
    dimensions.push(<dt key="1_1">{t("product.width")}</dt>);
    dimensions.push(<dd key="1_2">{data.depth} cm</dd>);
  }
  if (data.height) {
    dimensions.push(<dt key="2_1">{t("product.height")}</dt>);
    dimensions.push(<dd key="2_2">{data.height} cm</dd>);
  }
  if (data.diametre) {
    dimensions.push(<dt key="3_1">{t("product.diameter")}</dt>);
    dimensions.push(<dd key="3_2">{data.diametre} cm</dd>);
  }
  if (data.weight) {
    dimensions.push(<dt key="4_1">{t("product.weight")}</dt>);
    dimensions.push(<dd key="4_2">{data.weight} kg</dd>);
  }
  if (data.fragile) {
    dimensions.push(<dt key="5_1">{t("product.brittle")}</dt>);
    dimensions.push(<dd key="5_2">Produit fragile</dd>);
  }
  if (data.dimensions && data.dimensions.length > 0) {
    data.dimensions.forEach((dimension, index) => {
      if (dimension.libelle && dimension.valeur) {
        dimensions.push(<dt key={`${index + 6}_1`}>{dimension.libelle}</dt>);
        dimensions.push(<dd key={`${index + 6}_2`}>{dimension.valeur} cm</dd>);
      }
    });
  }

  let tabs = data.tabs ? data.tabs : [];
  if (dimensions) {
    tabs.unshift({
      title: "Dimensions",
      content: ``,
    });
  }

  let renseignements = [];
  if (data.features && data.features.length > 0) {
    data.features.forEach((feature, index) => {
      if (feature.libelle && feature.valeur) {
        renseignements.push(<dt key={`${index}_1`}>{feature.libelle}</dt>);
        renseignements.push(<dd key={`${index}_2`}>{feature.valeur}</dd>);
      }
    });
  }

  let name = data.name;
  let description = data.description;
  if (
    window.navigator.language.toLocaleLowerCase().substring(0, 2) !== "fr" &&
    data.traductions &&
    data.traductions.en
  ) {
    name = data.traductions.en.name ? data.traductions.en.name : name;
    description = data.traductions.en.description
      ? data.traductions.en.description
      : description;
  }

  return (
    <React.Fragment>
      <Title>{name}</Title>
      <Div>
        {data.photos && data.photos.length > 1 && (
          <PhotosEL>
            <Photos datas={data.photos} />
          </PhotosEL>
        )}

        <DescriptionEl>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: md.render(description) }}
          />

          <ShowPrice produit={document} />

          <h3>Dimensions</h3>
          {dimensions.length > 0 && <dl>{dimensions}</dl>}

          <h3>Renseignements</h3>
          {renseignements.length > 0 && <dl>{renseignements}</dl>}
        </DescriptionEl>
      </Div>
    </React.Fragment>
  );
};

Stock.propTypes = {
  document: PropTypes.object.isRequired,
};

export default Stock;
