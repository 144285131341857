import styled from "styled-components";
import bulle from "../../assets/images/bulle.png";
import bulleReponse from "../../assets/images/bulle-reponse.png";

const Produit = styled.li`
  color: #111;
  opacity: ${props => (props.isBuy || !props.isProduit ? 1 : 0.5)};
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #cdc6bc;
  display: flex;
  flex-direction: column;

  .produit {
    background: ${props => (props.isBuy ? " #cdc6bc" : "#fff")};
    padding: 0.75rem;
    border-radius: 5px;
    display: flex;
    flex-direction: row;

    > .title {
      font-weight: bold;
    }

    .demande {
      flex: 1;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;

      li {
        margin-bottom: 0.75rem;
        > ul > li {
          margin: 0;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .conversation {
    margin-top: 1.5rem;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-self: center;

    .message {
      max-width: 70%;
      position: relative;
      background: #e8e8e8;
      border-radius: 15px;
      padding: 0.375rem 0.75rem;
      margin-bottom: 0.75rem;
      align-self: flex-start;
      &:after {
        content: "";
        background: url(${bulle}) no-repeat;
        width: 19px;
        height: 15px;
        display: block;
        position: absolute;
        left: -5px;
        right: auto;
        bottom: 0;
      }
    }
    .message-reponse {
      max-width: 70%;
      position: relative;
      text-align: right;
      background: #cdc6bc;
      border-radius: 15px;
      padding: 0.375rem 0.75rem;
      align-self: flex-end;
      &:after {
        content: "";
        background: url(${bulleReponse}) no-repeat;
        width: 19px;
        height: 15px;
        display: block;
        position: absolute;
        left: auto;
        right: -5px;
        bottom: 0;
      }
    }
  }

  //.conversation {
  //  width: 50%;
  //  margin: 0.75rem auto 3rem auto;
  //  display: flex;
  //  flex-direction: column;
  //}

  .produit {
    display: flex;
    flex-direction: row;

    .infos {
      flex: 1;
    }

    .last-column {
      text-align: right;
      margin-left: 1.5rem;
    }

    .prix {
      font-weight: bold;
      .prix-infos {
        display: block;
      }

      .prix-barre {
        text-decoration: line-through;
      }

      .inventaire {
        display: block;
      }
    }
  }

  button {
    margin-top: 1.5rem;
    display: inline-block;
    //background: #bd0b02;
    border-radius: 4px;
    background: #b7ad9e;
    padding: 0.375rem 1.5rem;
    //color: #fff;
    cursor: pointer;
    text-decoration: none;
    font-size: 90%;

    &:hover {
      background: #333;
      color: #fff;
    }
  }

  .produit-photo {
    margin-right: 1.5rem;
    margin-bottom: 0;
    border-radius: 5px;
  }
`;

const Form = styled.form`
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    display: flex;
    width: 50%;
    input,
    textarea {
      box-sizing: border-box;
      flex: 1;
      background-color: #fff;
      //border: 1px solid #bbb7b1;
      //color: #666;
      border: none;
      font-size: 1rem;
      height: 3rem;
      padding: 0.75rem 1rem;
      margin: 0.375rem;
    }

    &.message {
      width: 100%;
    }
  }

  textarea {
    min-height: 80px;
  }

  button {
    display: inline-block;
    background: #000;
    padding: 0.375rem 0.75rem;
    color: #fff !important;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    margin-top: 1.5rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Coordonnees = styled.div`
  flex: 1;
  > div {
    span {
      display: block;
    }
  }
`;

const Message = styled.div`
  flex: 1;
`;

export { Produit, Form, Coordonnees, Message };
