import {
  PRODUIT_ADD_CART,
  PRODUIT_UPDATE_CART,
  PRODUIT_REMOVE_CART,
  PRODUIT_RESET_CART
} from "./actionTypes";

const initialState = {
  panier: []
};


export default function catalogueReducer(state = initialState, action) {
  switch (action.type) {
    case PRODUIT_ADD_CART: {
      const panier = state.panier.slice(0);
      const produitIndex = panier.findIndex(produit => {
        if (produit.productType === "variants") {
          return (
            produit.id === action.produit.id &&
            produit.variantId === action.produit.variantId
          );
        }
        return produit.id === action.produit.id;
      });

      if (produitIndex > -1) {
        // Le produit est déjà dans le panier
        panier[produitIndex].qty += parseInt(action.produit.qty, 10);
      } else {
        panier.push(action.produit);
      }

      return { ...state, panier };
    }

    case PRODUIT_UPDATE_CART: {
      const panier = state.panier.slice(0);
      const produitIndex = panier.findIndex(
        produit => produit.id === action.produit.id
      );
      if (produitIndex > -1) {
        // Le produit est dans le panier
        panier[produitIndex] = action.produit;
      }

      return { ...state, panier };
    }

    case PRODUIT_REMOVE_CART: {
      const panier = state.panier.slice(0);
      const produitIndex = panier.findIndex(
        produit => produit.id === action.produitId
      );
      if (produitIndex > -1) {
        // Le produit est dans le panier
        panier.splice(produitIndex, 1);
      }

      return { ...state, panier };
    }

    case PRODUIT_RESET_CART:
      return { ...state, panier: [] };

    default:
  }

  return state;
}
