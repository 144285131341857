import React, { Component } from "react";
import { Menu } from "../../template";
import styled from "styled-components";
import { Match } from "@reach/router";

const HeaderStyled = styled.header`
  ${props => {
    if (props.theme.mobile) {
      return {
        padding: 0,
        height: "48px",
        background: "rgba(246, 246, 246, 0)",
        transition: "background 0.35s linear, height 0.35s ease-in 200ms",
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 1500,
        [`&.is-open`]: {
          height: "100%",
          transitionDelay: "0s",
          background: "rgba(246, 246, 246, 1)",
          bottom: 0,
          [`.menu`]: {
            display: "block",
            [`ul`]: {
              visibility: "visible",
              transitionDelay: "0s",
              [`li`]: {
                transitionDelay: "300ms, 300ms",
                opacity: 1,
                pointerEvents: "auto",
                transform: "none"
              }
            }
          }
        }
      };
    }
  }};
`;

// const defaultStyle = component => {
//   return styled(component)`
//
//   `;
// };

class Header extends Component {
  menuItems = null;
  styleHeader = null;
  styleMenu = null;

  state = {
    openMenu: false
  };

  constructor(props, options) {
    super(props);

    if (options.openMenu) {
      this.state.openMenu = true;
    }

    if (options) {
      this.menuItems = options.menuItems;
      this.styleHeader = options.styleHeader;
      this.styleMenu = options.styleMenu;
    }

    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.handleRemoveMenu = this.handleRemoveMenu.bind(this);
    this.showMenu = this.showMenu.bind(this);
  }

  handleToggleMenu = e => {
    e.preventDefault();

    this.setState(oldState => {
      return {
        openMenu: !oldState.openMenu
      };
    });

    return null;
  };

  handleRemoveMenu = () => {
    this.setState({
      openMenu: false
    });
  };

  content() {
    return <div />;
  }

  contentAfter() {
    return null;
  }

  showMenu = () => (
    <Menu
      menuItems={this.menuItems}
      onClick={this.handleRemoveMenu}
      style={this.styleMenu}
    />
  );

  render() {
    const StyledHeader = this.styleHeader ? this.styleHeader : HeaderStyled;

    return (
      <Match path="/">
        {props => {
          const arrayClasses = [];

          if (this.state.openMenu) {
            arrayClasses.push("is-open");
          }
          if (props.match) {
            arrayClasses.push("is-home");
          }

          return (
            <StyledHeader className={arrayClasses.concat(" ")}>
              {this.content()}
              {this.showMenu()}
              {this.contentAfter()}
            </StyledHeader>
          );
        }}
      </Match>
    );
  }
}

export default Header;
