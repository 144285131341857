import { getFirestore } from "../config/functions";

class Url {
  static search(wheres) {
    let query = getFirestore()
      .collection("urls")
      .where("boutique", "==", process.env.REACT_APP_ID_BOUTIQUE)
      .where("deleted", "==", false);

    if (wheres && Object.keys(wheres).length > 0) {
      Object.keys(wheres).forEach(key => {
        if (wheres[key]) {
          query = query.where(String(key), "==", wheres[key]);
        } else {
          if (parseInt(wheres[key], 10) === 0) {
            query = query.where(String(key), "==", 0);
          } else {
            query = query.where(String(key), "==", false);
          }
        }
      });
    }
    return query.get().then(querySnapshot => {
      return querySnapshot.docs;
    });
  }

  /**
   * Cette methode permet de regarder les urls d'une boutique
   *
   * @param wheres
   * @param next
   * @returns {unsubscribe}
   */
  static watch(wheres, next) {
    let query = getFirestore()
      .collection("urls")
      .where("boutique", "==", process.env.REACT_APP_ID_BOUTIQUE)
      .where("deleted", "==", false);

    if (wheres && Object.keys(wheres).length > 0) {
      Object.keys(wheres).forEach(key => {
        if (wheres[key]) {
          query = query.where(String(key), "==", wheres[key]);
        } else {
          if (parseInt(wheres[key], 10) === 0) {
            query = query.where(String(key), "==", 0);
          } else {
            query = query.where(String(key), "==", false);
          }
        }
      });
    }

    return query.onSnapshot(next);
  }

  /**
   * Recherche une vente par l'intermédiaire de l'id
   *
   * @param boutique
   * @param id
   * @returns {Promise<unknown>}
   */
  static findById(boutique, id) {
    return getFirestore(boutique)
      .collection("urls")
      .doc(id)
      .get()
      .then(documentSnapshot => {
        if (!documentSnapshot.exists) {
          throw new Error("L'url n'existe pas");
        }

        if (
          documentSnapshot.get("boutique") !== process.env.REACT_APP_ID_BOUTIQUE
        ) {
          throw new Error("Cette url n'appartient pas à cette boutique");
        }

        if (documentSnapshot.get("deleted")) {
          throw new Error("Cette url a été supprimé");
        }

        return documentSnapshot;
      });
  }

  /**
   *
   * @param pathname
   * @returns {Promise<firebase.firestore.QueryDocumentSnapshot>}
   */
  static findByPathname(pathname) {
    return getFirestore()
      .collection("urls")
      .where("boutique", "==", process.env.REACT_APP_ID_BOUTIQUE)
      .where("deleted", "==", false)
      .where("pathname", "==", pathname)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.empty) {
          throw new Error("L'url n'existe pas");
        }

        if (querySnapshot.size > 1) {
          throw new Error("Cette url n'est pas unique");
        }

        return querySnapshot.docs[0];
      });
  }
}

export default Url;
