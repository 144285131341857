import React, { Component } from "react";
import { Content, Form } from "../template";
import Action from "../innedit_src/datas/Action";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(datas) {
    return Action.create(datas, "demande-create").then((documentReference) => {
      this.props.navigate(`/contact/${documentReference.id}`);
      return new Promise((resolve, reject) => {
        resolve({
          success: true,
          message: "Enregistrement réussi",
        });
      });
    });
  }

  render() {
    // TODO faire un composant à part
    if (this.props["*"]) {
      // C'est la page de confirmation d'une demande de contact
      // TODO ajouter la possibilité de pouvoir intéragir sur cette page pour améliorer les échanges avec le client
      return (
        <Content
          className="center"
          title="Confirmation"
          head={{
            title: "Contact - Matthieu Leclercq Antiquités",
          }}
        >
          <div style={{ width: "300px", margin: "0 auto" }}>
            <p style={{ textAlign: "left" }}>
              Votre demande a bien été prise en compte. Vous allez être
              directement contacter par mail sous 24-48h.
            </p>
            <p style={{ textAlign: "left" }}>En vous remerciant par avance.</p>
            <p style={{ textAlign: "center" }}>Matthieu Leclercq Antiquités</p>
          </div>
        </Content>
      );
    }

    return (
      <Content
        title="Contact"
        head={{
          title:
            "Besoin d'un renseignement ? Contactez-moi - Leclercq Antiquités",
        }}
      >
        <div className="second">
          {/*<div className='devis'>*/}
          {/*<h3>Devis</h3>*/}
          {/*<p>Vous avez 2 minutes ? Nous vous invitons à remplir notre questionnaire pour mieux vous aider.</p>*/}
          {/*<Link className='link center' to='/devis/'>Remplir le questionnaire</Link>*/}
          {/*</div>*/}
          <div
            className="carte"
            style={{ textAlign: "center", marginBottom: "3rem" }}
          >
            <p>Mon showroom est situé dans le Nord de la France, à Bondues :</p>
            <address>
              <span style={{ display: "block" }}>156 rue du Dronckaert</span>
              <span style={{ display: "block" }}>59223 Roncq</span>
              <span style={{ display: "block" }}>France</span>
            </address>
            <a
              href="https://www.google.fr/maps/dir/''/156+rue+du+Dronckaert,+Roncq/"
              target="_blank"
              rel="noopener noreferrer"
              className="link center"
            >
              Itinéraire
            </a>

            <p>
              Vous pouvez également me contacter directement par téléphone au
              +33 6 12 41 04 94.
            </p>
          </div>
        </div>
        <Form
          onSubmit={this.handleSubmit}
          datas={this.props.datas}
          className="main"
          textSubmit="Envoyer votre demande"
        >
          <p style={{ textAlign: "center" }}>
            Si vous avez une question particulière ou tout simplement envie de
            me dire Bonjour ? N'hésitez pas à utiliser le formulaire de contact.
          </p>
          <p style={{ textAlign: "center", marginBottom: "3rem" }}>
            Je serai ravi de vous répondre.
          </p>
        </Form>
      </Content>
    );
  }
}

Contact.defaultProps = {
  datas: {
    contact_firstname: {
      label: "Prénom",
      type: "text",
    },
    contact_lastname: {
      label: "Nom",
      type: "text",
    },
    contact_phone: {
      label: "Téléphone",
      type: "text",
    },
    contact_email: {
      label: "Adresse e-mail",
      required: true,
      type: "email",
    },
    contact_entreprise: {
      label: "Entreprise",
      type: "text",
    },
    contact_city: {
      label: "Ville",
      type: "text",
    },
    content: {
      label: "Commentaires / Questions",
      component: "textarea",
      componentStyle: { minHeight: "180px" },
      required: true,
      style: { width: "100%" },
    },
  },
};

export default Contact;
