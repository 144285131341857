import React from "react";
import styled from "styled-components";

const Span = styled.span`
  display: block;
  font-weight: 100;
  color: #97b7ac;

  &.price,
  &.start_price {
    &:after {
      content: " €";
      //margin-left: 0.1875rem;
      font-size: 85%;
    }
  }
`;

const Prix = ({ produit, info }) => {
  const achatPossible =
    (produit.hasInventory &&
      (produit.qtyAvailable > 0 || produit.negativeStock)) ||
    !produit.hasInventory;

  switch (produit.optionPrice) {
    case "priceRequest":
      if (achatPossible) {
        return <Span className="info">{info ? info : "Prix sur demande"}</Span>;
      }
      return <Span className="vendu">Vendu</Span>;

    case "showPrice":
    default:
      const retailPrice = produit.retailPrice
        ? produit.shippingIncluded
          ? parseFloat(produit.retailPrice) +
            parseFloat(produit.estimateShippingCost)
          : produit.retailPrice
        : false;
      const price = produit.shippingIncluded
        ? parseFloat(produit.price) + parseFloat(produit.estimateShippingCost)
        : produit.price;

      if (!price || typeof price !== "number" || price < 0) {
        return null;
      }

      if (produit.productType === "variants") {
        if (produit.variantId) {
          return <Span className="price">{price}</Span>;
        }
        return <Span className="start_price">A partir de {price}</Span>;
      }

      if (!retailPrice) {
        return <Span className="price">{price}</Span>;
      }

      return (
        <div>
          {retailPrice && <Span className="retail-price">{retailPrice}</Span>}
          {price && <Span className="price">{price}</Span>}
        </div>
      );
  }
};

export default Prix;
