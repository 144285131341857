import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Breadcrumbs, Content, Tabs } from "../../../template";
const md = require("markdown-it")();

class Memo extends Component {
  state = {
    article: this.props.item
  };

  render() {
    const data = this.state.article;
    const head = this.props.head;

    if (!data) {
      return (
        <div>il y a un problème de params, l'article n'est pas présent</div>
      );
    }

    let tabs = data.tabs ? data.tabs : [];

    let values = [];
    values.push({
      mobile: true,
      to: "/actualites",
      value: "Actualités"
    });
    switch (data.category) {
      case "HsUXDbfb8tLbIac5a6nA":
        values.push({
          mobile: true,
          to: "/tables",
          value: "Tables"
        });
        break;

      case "Ah48tqDv6TaMyFwnTGK0":
        values.push({
          mobile: true,
          to: "/miroirs",
          value: "Miroirs"
        });
        break;

      case "plQnetzWo1MP9NPAJpYS":
        values.push({
          mobile: true,
          to: "/meubles",
          value: "Meubles"
        });
        break;

      default:
    }

    const tmp = Object.assign({}, head);
    if (head && head.title && data.title) {
      tmp.title = `${data.title} - ${head.title}`;
    }

    return (
      <div>
        <Breadcrumbs values={values} title={data.title} />
        <Content title={data.title} head={tmp}>
          {data.bodyMarkdown ? (
            <div
              dangerouslySetInnerHTML={{ __html: md.render(data.bodyMarkdown) }}
            />
          ) : data.content ? (
            <div
              dangerouslySetInnerHTML={{ __html: md.render(data.content) }}
            />
          ) : null}
        </Content>
        {tabs.length > 0 && <Tabs values={tabs} />}
      </div>
    );
  }
}

Memo.propTypes = {
  item: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Memo);
