import React from "react";
import styled from "styled-components";
import { Header, Footer } from "../template";

const LayoutEL = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // max-width: ${(props) => (props.theme.mobile ? "100%" : "1250px")};
  width: ${(props) => (props.theme.mobile ? "100%" : "auto")};
  box-sizing: border-box;
  padding: 0;
  margin: 0 auto;
`;

const Body = styled.div`
  padding: ${(props) => (props.theme.mobile ? "63px 15px 0 15px" : "0 15px")};
  flex: 1;
`;

const Layout = ({ loading, children, top = 0 }) => {
  return (
    <LayoutEL>
      <Header />
      <Body>{loading ? <div>Chargement en cours</div> : children}</Body>
      <Footer />
    </LayoutEL>
  );
};

export default Layout;
