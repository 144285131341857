import React, { Component } from "react";
import styled from "styled-components";
import DefaultStyle from "../../styles/Field";

class Field extends Component {
  style = null;

  constructor(props, options) {
    super(props);
    if (options) {
      this.style = options.style;
    }
  }

  render() {
    const {
      label,
      component,
      componentStyle,
      type,
      name,
      value,
      placeholder,
      required,
      hideRequired,
      style,
      onChange,
      hidden,
      className,
      description
    } = this.props;
    let StyledField = this.style ? styled(this.style)`` : DefaultStyle;
    let field = null;

    switch (component) {
      case "textarea":
        field = (
          <textarea
            name={name}
            value={value}
            style={componentStyle}
            required={required}
            onChange={onChange}
            placeholder={placeholder}
          />
        );
        break;

      default:
        switch (type) {
          case "checkbox":
            field = (
              <span className="checkbox">
                <input
                  type="checkbox"
                  name={name}
                  checked={Boolean(value)}
                  style={componentStyle}
                  onChange={onChange}
                  id={name}
                />
                <label
                  htmlFor={name}
                  style={{ marginLeft: "0.75rem", display: "inline-block" }}
                >
                  {description}
                </label>
              </span>
            );
            break;

          default:
            field = (
              <input
                type={type}
                name={name}
                value={value}
                style={componentStyle}
                required={required}
                onChange={onChange}
                placeholder={placeholder}
                autoComplete="off"
                id={name}
              />
            );
        }
    }

    return (
      <StyledField
        className={`field ${className ? className : ""} ${
          hidden ? "hidden" : ""
        }`}
        style={style}
      >
        {label && (
          <label>
            {label}
            {required && !hideRequired && <small>Obligatoire</small>}
          </label>
        )}
        {field}
      </StyledField>
    );
  }
}

export default Field;
