import React, { Component } from "react";
import { Link } from "@reach/router";
import styled from "styled-components";

const StyledButton = styled.button`
  appearance: none !important;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 400;
  margin-top: 0.75rem;
  margin-right: ${props => (props.theme.mobile ? 0 : "0.375rem")};
  margin-bottom: 0.75rem;
  margin-left: ${props => (props.theme.mobile ? 0 : "0.375rem")};
  width: ${props => (props.theme.mobile ? "100%" : "auto")};
  text-align: center;
  text-decoration: none;
  display: inline-block;
  background: ${props => (props.primary ? "#444" : "#ccc")};
  border-color: ${props => (props.primary ? "#444" : "#ccc")};
  color: ${props => (props.primary ? "#fff !important" : "#666")};
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  outline: 0;
  vertical-align: middle;
  text-transform: uppercase;
  line-height: 1rem;
  font-family: "Advent Pro", sans-serif;
`;


class Button extends Component {
  style = null;

  constructor(props, options) {
    super(props);
    if (options) {
      this.style = options.style;
    }
  }

  render() {
    let { to, className, children, ...others } = this.props;
    const props = {};
    let ButtonElement = this.style ? styled(this.style)`` : StyledButton;

    if (to) {
      props.to = to;
      props.as = Link;
      // ButtonElement = ButtonElement.withComponent(Link);
    }

    return (
      <ButtonElement {...props} {...others} className={className}>
        {children}
      </ButtonElement>
    );
  }
}

export default Button;
