import React, { Component } from "react";
import { Content } from "../../template";
import CMSContent from "../cms/components/Content";

class Page extends Component {
  state = {
    document: undefined,
    contents: undefined,
    mustBeLoad: false,
    unsubscribe: undefined
  };

  constructor(props) {
    super(props);
    this.loadPage = this.loadPage.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.document &&
      (!state.document || state.document.id !== props.document.id)
    ) {
      state.mustBeLoad = true;
      state.document = props.document;
      state.contents = undefined;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.mustBeLoad) {
      this.loadPage();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoad) {
      this.loadPage();
    }
  }

  loadPage() {
    this.setState({
      mustBeLoad: false
    });
    if (this.state.unsubscribe) {
      this.state.unsubscribe();
    }

    const unsubscribe = this.state.document.ref
      .collection("contents")
      .where("deleted", "==", false)
      .orderBy("order", "asc")
      .onSnapshot(querySnapshot => {
        this.setState({
          contents: querySnapshot.docs
        });
      });

    this.setState({
      unsubscribe: unsubscribe
    });
  }

  componentWillUnmount() {
    if (this.state.unsubscribe) {
      this.state.unsubscribe();
    }
  }

  render() {
    const { document, head } = this.props;
    const data = document.data();
    const tmp = Object.assign({}, head);
    const title = data.title ? data.title : data.name;

    if (data.metaKeywords) {
      if (!head.meta) {
        head.meta = [];
      }
      head.meta.push({
        name: "keywords",
        content: data.metaKeywords
      });
    }

    if (data.metaDescription) {
      if (!head.meta) {
        head.meta = [];
      }
      head.meta.push({
        name: "description",
        content: data.metaDescription
      });
    }

    if (head && head.title) {
      tmp.title = `${title} - ${head.title}`;
    }

    return (
      <Content
        title={data.altNavTitle ? data.altNavTitle : data.name}
        head={tmp}
      >
        {this.state.contents && this.state.contents.length > 0 && (
          <div className="sandwich-cms-contents">
            {this.state.contents.map((content, index) => (
              <CMSContent item={content} key={index} />
            ))}
          </div>
        )}
      </Content>
    );
  }
}
export default Page;
